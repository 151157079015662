import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from './../../components/pageheader';
import { Table, Row, Col } from 'react-bootstrap';
import style from './invoices.scss';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ErrorLoading, PageLoading } from "../../components/loading";
import { EditFeeNote } from './feenote-manipulation';
import moment from 'moment';
import { actionFeeNote, getFeeNote } from '../../resources/api/fee-notes';
import { UCWords } from '../../components/resources';
import { feeNoteSatuses, firmDetails } from '../../assets/constants';
import cur from '../../components/currency';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { ConfirmAction } from '../../components/prompt';
import AddPayment from './details/payment';
import { APIURL } from '../../resources/fetch';



/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/fee-notes').NoteObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/fee-notes').NoteObject>>} props.setDetails
 * @returns 
 */
const ViewFeeNote = ({ details, setDetails }) => {

    const items = [
        { title: 'Fee Notes', href: '/app/fee-notes' },
        { title: details.reference }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    const [showPayment, setShowPayment] = useState(false);


    const advanceFeeNote = (status) => {

        infoAlert("Please hold...");

        actionFeeNote(details.id, status)
            .then(({ note, message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: note.status }))

            })
            .catch(e => errorAlert(e))
    }

    const handlePaymentAdd = (payment) => {

        setDetails(d => ({
            ...d,
            payments: d.payments.concat(payment),
            paid_amount: d.paid_amount + payment.amount,
            balance: d.balance - payment.amount,
            status: ((d.balance - payment.amount) < 1) ? "paid" : "paritally-paid"
        }))
    }


    return (
        <>
            <Nav items={items} />

            <PageHeader maxWidth="1000">
                <Title>
                    No: {`${details.note_no}`.padStart(6, "0")}
                </Title>
                <ActionBar>
                    {!details.isOld &&
                        <ButtonToolbar>
                            <Btn title="View Fee Note PDF" onClick={() => window.open(`${APIURL}/files/feenote/${details.id}`, "_blank")}>
                                <i className="fas fa-file-pdf" />
                            </Btn>
                        </ButtonToolbar>}


                    {([feeNoteSatuses[2], feeNoteSatuses[3]].indexOf(details.status) !== -1) &&
                        <ButtonToolbar>
                            <Btn title="Add Payment" onClick={() => setShowPayment(true)}>
                                <i className="fas fa-coins" />
                            </Btn>
                        </ButtonToolbar>}
                    <ButtonToolbar>

                        {(details.status === feeNoteSatuses[1]) &&
                            <ConfirmAction
                                as={Btn}
                                title="Make Draft"
                                handleAction={() => advanceFeeNote('draft')}
                                confirmationText="Are you sure you want to make this fee note as draft?"
                            >
                                <i className="fas fa-pencil-ruler" />
                            </ConfirmAction>}

                        {(details.status === feeNoteSatuses[0]) &&
                            <ConfirmAction
                                as={Btn}
                                title="Mark as Sent"
                                handleAction={() => advanceFeeNote('sent')}
                                confirmationText="Are you sure you want to mark this fee note as sent?"
                            >
                                <i className="far fa-paper-plane" />
                            </ConfirmAction>}
                        {(details.status === feeNoteSatuses[1]) &&
                            <ConfirmAction
                                as={Btn}
                                title="Mark as Accepted"
                                handleAction={() => advanceFeeNote('accept')}
                                confirmationText="Are you sure you want to mark this fee note as accepted?"
                            >
                                <i className="far fa-check-circle" />
                            </ConfirmAction>}
                        {(details.status === feeNoteSatuses[1]) &&
                            <ConfirmAction
                                as={Btn}
                                title="Cancel Fee Note"
                                handleAction={() => advanceFeeNote('cancel')}
                                confirmationText="Are you sure you want to cancel this fee note?"
                            >
                                <i className="fas fa-times" />
                            </ConfirmAction>}
                        {([feeNoteSatuses[2], [feeNoteSatuses[3]]].indexOf(details.status) !== -1) &&
                            <ConfirmAction
                                as={Btn}
                                title="Void Fee Note"
                                handleAction={() => advanceFeeNote('void')}
                                confirmationText="Are you sure you want to void this fee note?"
                            >
                                <i className="fas fa-ban" />
                            </ConfirmAction>}
                    </ButtonToolbar>


                    <ButtonToolbar>
                        {(details.status === feeNoteSatuses[0]) &&
                            <Btn title="Edit Fee Note" href={`/app/fee-notes/${details.id}/edit`}>
                                <i className="fas fa-pencil-alt" />
                            </Btn>}
                        <Btn href="/app/fee-notes/new" title="New Fee Note">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000 p-1">

                <div className="text-end">
                    FEE NOTE #: <span style={style1}>{`${details.note_no}`.padStart(6, "0")}</span> <br />
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    DATE: <span style={style1}>{moment(details.feenote_date).format("DD MMMM YYYY")}</span> <br />
                    TIN: <span style={style1}>{firmDetails.tin}</span> <br />
                    ACCOUNT NO: <span style={style1}>{firmDetails.account_no}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span><br />
                </div>

                <Row className="mt-5 mb-3 g-1">
                    {/* <Col sm={6} className='mt-2'>
                        <h5>From</h5>

                        <span className="lead">NASSUUNA &amp; COMPANY ADVOCATES</span> <br />
                        3rd floor Northern Wing, Social Security House <br />
                        Plot 4 Jinja Road, P.O.Box 8728 Kampala <br />
                        Tel: +256414599745, Mob: +256752648063 <br />
                    </Col> */}
                    <Col sm={6} className='mt-2'>
                        <h5>Client Details</h5>

                        <span className="h6 fw-bold">{details.account.title.toUpperCase()}</span> <br />
                        {/* 121/115 Block 6, Katwe <br />
                        P.O.BOX 6972 Kampala */}
                    </Col>
                </Row>

                <h5>Instruction Details</h5>

                <p>{details.instruction || <span className="text-muted">No instruction uploaded</span>}</p>

                {!!details.isOld &&
                    <div className='text-info lead fw-bold mt-3'>
                        This fee note was part of the old system. You cannot see the split of the items or schedule.
                    </div>
                }

                {!details.isOld &&
                    <>
                        <ViewNoteSchedule items={details.schedule_items} />
                        <ViewNoteItems items={details.items} />
                    </>
                }


                <AddPayment
                    show={showPayment}
                    setShow={setShowPayment}
                    noteid={details.id}
                    maxAmount={details.balance}
                    onAdd={handlePaymentAdd}
                />


                <div className="mt-5 mb-2">
                    <h5>Amounts</h5>
                    <Table size="sm" style={{ maxWidth: "300px" }}>
                        <tbody>
                            <tr>
                                <td>Total</td>
                                <td className='text-end'>{cur(details.total_amount).format()}</td>
                            </tr>
                            <tr>
                                <td>Payments Made</td>
                                <td className='text-end'>{cur(details.paid_amount).format()}</td>
                            </tr>
                            <tr>
                                <td>Balance</td>
                                <td className='text-end'>{cur(details.balance).format()}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>


                {(details.payments.length > 0) &&
                    <div className="mt-5 mb-2">
                        <h5>Payments</h5>
                        <Table size="sm" style={{ maxWidth: "300px" }}>
                            <thead>
                                <tr>
                                    <th>Ref</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.payments.map(p => (
                                    <tr key={p.id}>
                                        <td>{p.reference}</td>
                                        <td>{moment(p.payment_date).format("dd DD MMM YYYY")}</td>
                                        <td className='text-end'>{cur(p.amount).format()}</td>
                                    </tr>
                                ))}

                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={2}>Total</th>
                                    <th className='text-end'>{cur(details.payments.reduce((p, c) => p + parseFloat(c.amount), 0)).format()}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>}

                {(details.expenses.length > 0) &&
                    <div className="mt-5 mb-2">
                        <h5>Expenses</h5>
                        <Table size="sm" style={{ maxWidth: "300px" }}>
                            <thead>
                                <tr>
                                    <th>Ref</th>
                                    <th>Title</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.expenses.map(p => (
                                    <tr key={p.id}>
                                        <td><Link to={`/app/expenses/${p.id}`}>{p.reference}</Link></td>
                                        <td>{moment(p.expense_date).format("dd DD MMM YYYY")}</td>
                                        <td className='text-end'>{cur(p.amount).format()}</td>
                                    </tr>
                                ))}

                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={2}>Total</th>
                                    <th className='text-end'>{cur(details.expenses.reduce((p, c) => p + parseFloat(c.amount), 0)).format()}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>}


            </div>
        </>
    )
}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/fee-notes').ScheduleItem[]} props.items 
 * @returns 
 */
const ViewNoteSchedule = ({ items }) => {

    return (
        <div>
            <h5>Titles on this FeeNote</h5>
            <Table responsive style={{ minWidth: "300px", maxWidth: "550px" }} hover size='sm'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Customer</th>
                        <th className='text-end'>Loan Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(i => (
                        <tr key={i.id}>
                            <td style={{ whiteSpace: "nowrap" }}>
                                <Link to={`/app/titles/${i.title_id}`}>{i.title}</Link>
                            </td>
                            <td>{i.customer}</td>
                            <td className='text-end'>{cur(i.loan_amount, 0).format()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    )
}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/fee-notes').NoteItem[]} props.items 
 * @returns 
 */
const ViewNoteItems = ({ items }) => {

    return (
        <div className={`mt-5 mb-2 ${style}`}>

            <Table responsive style={{ minWidth: '450px' }} hover className="items-table">
                <colgroup>
                    <col span="1" style={{ width: "70%" }} />
                    <col span="1" style={{ width: "30%", textAlign: 'center' }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>Instructions</th>
                        <th>Amount (UGX)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((i, idx) => (
                            <tr key={idx}>
                                <td>
                                    <span className="d-block font-weight-normal">{i.title}</span>
                                    {!!i.description &&
                                        <div className="description">{i.description}</div>}
                                </td>
                                <td>{cur(i.amount).format()}</td>
                            </tr>
                        ))
                    }
                </tbody>
                <tfoot>
                    <tr className="fw-bold lead">
                        <td>GRAND TOTAL</td>
                        <td>{cur(items.reduce((p, c) => p + c.amount, 0)).format()}</td>
                    </tr>
                </tfoot>

            </Table>

        </div>
    )
}


const FeeNoteDetails = () => {

    const { noteid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getFeeNote(noteid, ['account', 'items', 'schedule_items', 'expenses', 'payments'])
            .then(({ feenote }) => {
                setDetails(feenote);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [noteid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading fee-note details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditFeeNote details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewFeeNote details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default FeeNoteDetails;