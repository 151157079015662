
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
import { ErrorLoading } from '../../components/loading';
import PageHeader, { Title } from '../../components/pageheader';
import FeeNoteForm from './feenote-form';

const noteObject = {
    feenote_date: "", account_id: "", title_id: "", title: "",
    description: "", items: [], notes: []
}

const NewFeeNote = () => {

    const nav_items = [
        { title: 'Fee Notes', href: '/app/fee-notes' },
        { title: 'New Fee Note' }
    ];

    const navigate = useNavigate();

    const { profile } = useContext(AppUserContext);

    const handleSubmit = (details) => navigate(`/app/fee-notes/${details.id}`);

    if (profile.roles.indexOf('finance') === -1) {
        return <ErrorLoading>You do not have permission to create an expense.</ErrorLoading>;
    }

    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <FeeNoteForm
                    details={noteObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('./view-feenotes').FeeNoteObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/fee-notes').NoteObject>>} props.setDetails 
 */
const EditFeeNote = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Fee Notes', href: '/app/fee-notes' },
        { title: details.reference, href: `/app/fee-notes/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const { profile } = useContext(AppUserContext);

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/fee-notes/${details.id}`);
    }

    if (profile.roles.indexOf('finance') === -1) {
        return <ErrorLoading>You do not have permission to create an expense.</ErrorLoading>;
    }


    return (

        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.title}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <FeeNoteForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewFeeNote, EditFeeNote };