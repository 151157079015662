
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from '../../components/loading';
import PageHeader, { Title } from '../../components/pageheader';
import TitleForm from './title-form';

const titleObject = {
    title: "", instruction_date: "", instrument_no: "", block: "",
    plot: "", customer: "", county: "", registered_proprietor: "",
    loan_amount: "", land_office: "", feenote_amount: "",
    land_type: "", volume: "", folio: "",
    instruction_letter_id: "",
    batch_ref: ""
}

const NewTitle = () => {

    const nav_items = [
        { title: 'Titles', href: '/app/titles' },
        { title: 'New Title' }
    ];

    const { profile } = useContext(AppUserContext);

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate(`/app/titles/${details.id}`);
    }


    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(titleObject);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        let account_id = searchParams.get('account') || "",
            batch_ref = searchParams.get('batch_ref') || "";

        const _details = { ...details, account_id, batch_ref };

        setDetails(_details);
        setLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (profile.roles.indexOf("relationship-manager") === -1) {
        return <ErrorLoading>You must have the role of "Relationship Manager" to create a title.</ErrorLoading>
    }

    if (!loaded) return <PageLoading />



    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>Create New Title</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <TitleForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('./view-titles').TitleObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<{}>>} props.setDetails
 */
const EditTitle = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Titles', href: '/app/titles' },
        { title: details.title, href: `/app/titles/${details.id}` },
        { title: 'Edit Details' }
    ];

    const { profile } = useContext(AppUserContext);

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/titles/${details.id}`);
    }


    if (profile.roles.indexOf("relationship-manager") === -1) {
        return <ErrorLoading>You must have the role of "Relationship Manager" to edit details of a title.</ErrorLoading>
    }

    return (

        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.title}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <TitleForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewTitle, EditTitle };