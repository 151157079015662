import { APIURL, getData, postData, deleteData } from "../fetch";

/**
 * 
 * @typedef {{
 *  id: string 
 *  title_id: string
 *  content: string 
 *  added_by: string 
 *  date_added: string 
 *  display_name: string
 * }} NoteObject
 */

/**
 * 
 * @param {import("../../app/titles/view-titles").TitleObject} data 
 * @returns {Promise<{code: number, message: string, title: import("../../app/titles/view-titles").TitleObject}>}
 */
const createTitle = data => {
    return postData({
        url: `${APIURL}/titles`,
        data
    })
}

/**
 * 
 * @param {import("../../app/titles/view-titles").TitleObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, title: import("../../app/titles/view-titles").TitleObject}>}
 */
const updateTitle = (data, id) => {
    return postData({
        url: `${APIURL}/titles/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @param {{status: string|string[], id: string|string[], account_id: string}} conditions
 * @returns {Promise<{code: number, message: string, titles: import("../../app/titles/view-titles").TitleObject[]}>}
 */
const getTitles = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/titles` };
    let data = {};

    if (getAll) data.expanded = 1;
    if (conditions) data = { ...data, ...conditions };
    if (Object.keys(data).length > 0) obj.data = data;

    return getData(obj)

}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, title: import("../../app/titles/view-titles").TitleObject}>}
 */
const getTitle = (id, includes = null) => {

    const obj = { url: `${APIURL}/titles/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}

/**
 * 
 * @param {string} id 
 * @param {import("../../app/titles/view-titles").TitleDocObject} data
 * @returns  {Promise<{code: number, message: string, document: import("../../app/titles/view-titles").TitleDocObject}>}
 */

const uploadDocument = (id, data) => postData({ url: `${APIURL}/titles/${id}/documents`, data })


/**
 * Create notes
 * @param {string} id 
 * @param {string} content of the note 
 * @returns  {Promise<{code: number, message: string, note: NoteObject}>}
 */
const createNote = (id, content) => {

    const obj = { url: `${APIURL}/notes`, data: { content, title_id: id } };

    return postData(obj);
}

/**
 * Advance status
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string, title: {id: string, status: string}}>}
 */
const advanceTitleStatus = (id, data) => postData({ url: `${APIURL}/titles/${id}/status`, data });

/**
 * Advance status
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string, title: {id: string, status: string}}>}
 */
const reverseTitleStatus = (id, new_status) => postData({ url: `${APIURL}/titles/${id}/reverse/${new_status}` });

/**
 * 
 * @param {string} id 
 * @param {"returned"|"unresolved"} status 
 * @param {{reason: ?string, batch_titles: string[], activity_date: ?string}} data 
 * @returns {Promise<{code: number, message: string, title: {id: string, status: string, turnaround_time: number}}>}
 */
const closeTitle = (id, status, data) => postData({ url: `${APIURL}/titles/${id}/close/${status}`, data })



/**
 * Delete a note
 * @param {string} id ID of the note
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteNote = id => deleteData({ url: `${APIURL}/notes/${id}` });


/**
 * 
 * @returns {Promise<{code:number, message: string, batch_refs: string[]}>}
 */
const getBatchRefs = (account_id) => {

    const obj = { url: `${APIURL}/titles/batch-refs` };
    if (account_id) obj.data = { account_id };

    return getData(obj);
};


export {
    createTitle,
    getTitle,
    getTitles,
    updateTitle,
    uploadDocument,
    createNote,
    deleteNote,
    advanceTitleStatus,
    reverseTitleStatus,
    closeTitle,
    getBatchRefs
}