import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn } from '../../components/pageheader';
import { Form, Table, Card, Row, Col } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import { Link } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import moment from 'moment';
import { sortFunction } from "../../components/resources";
import { getAccounts } from '../../resources/api/accounts';
import { ErrorLoading, PageLoading } from '../../components/loading';
import cur from '../../components/currency';


/**
 * 
 * @typedef {{
 * id: string,
 * title: string,
 * description: string, 
 * feenoteAtEnd: boolean
 * contact_persons: {name: string, email: string, telephone: string}[],
 * relationship_managers: {display_name: string, other_names: string, last_name: string, email: string, telephone: string}[], 
 * no_titles: number, 
 * no_pending_titles: number, 
 * average_turnaround: number,
 * total_feenote: number,
 * date_added: string,
 * added_by: string
 * }} AccountObject 
 */

/**
 * View table items on screens larger than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {AccountObject[]} props.items
 * @param {number} props.currentPage
 * @param {number} props.noPageItems
*/
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {

    const El = ({ field, children, ...props }) => <th {...props}>{children} <SortButton field={field} {...srt} handleSort={handleSort} /></th>;

    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "20%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "20%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <El field="title">Title</El>
                    <El field="no_titles">No Titles</El>
                    <El field="no_pending_titles">Overdue</El>
                    <El field="average_turnaround">Turn Around</El>
                    <El field="total_feenote" className="text-end">Fee Note</El>
                    <El field="date_added">Date Added</El>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={`/app/accounts/${e.id}`}>{e.title}</Link>
                        </td>
                        <td>{e.no_titles}</td>
                        <td>{e.no_pending_titles}</td>
                        <td>{e.average_turnaround}</td>
                        <td className='text-end'>{cur(e.total_feenote).format()}</td>
                        <td>{moment(e.date_added).format("DD MMM YYYY")}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {AccountObject[]} props.items
 */
const ViewMobile = ({ srt, handleSort, items }) => {

    const El = ({ field, children }) => (
        <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
            {children} <SortButton field={field} {...srt} handleSort={handleSort} />
        </span>
    )
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <El field="title">Title</El>
                <El field="no_titles">No Titles</El>
                <El field="no_pending_titles">Overdue Titles</El>
                <El field="average_turnaround">Avg Turn Around</El>
                <El field="added_by">Added By</El>
                <El field="date_added">Date Added</El>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span className="text-secondary font-weight-normal">{e.no_pending_titles} pending titles</span>
                            <span className="text-muted font-weight-normal">Added {moment(e.date_added).format("DD MMM YYYY")}</span>
                        </div>

                        <div className="lead mb-2">
                            <Link to={`/app/accounts/${e.id}`} className="stretched-link">{e.title}</Link>
                        </div>
                        <Row>
                            <Col xs={4} className="small">
                                No Titles
                            </Col>
                            <Col xs={8}>
                                {e.no_titles}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                Turn Around
                            </Col>
                            <Col xs={8}>
                                {e.average_turnaround} days
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                Fee Notes
                            </Col>
                            <Col xs={8}>
                                {cur(e.total_feenote).format()}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}

/**
 * module that handles view of accounts
 * match path /app/accounts
 */
const ViewAccounts = () => {

    const navItems = [{ title: 'Accounts' }];

    const [srt, setSrt] = useState({
        currentField: 'title',
        currentOrder: 'asc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'title' });
    const [loaded, setLoaded] = useState(false);
    const [err, setError] = useState();

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)]);
    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        setLoaded(false);

        getAccounts()
            .then(({ accounts }) => {
                setOriginalItems(accounts);
                setItems(accounts);
            })
            .catch(err => { setError(err) })
            .finally(() => setLoaded(true))

    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder);

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    if(!loaded) return <PageLoading>Loading all accounts ...</PageLoading>

    if(err) return <ErrorLoading>{err}</ErrorLoading>


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Accounts</Title>
                <ActionBar>
                    <Btn href="/app/accounts/new">
                        <i className="fas fa-plus-circle me-1" />New Account
                    </Btn>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="title">Title</option>
                        </Form.Select>
                    </Col>


                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>
        </>
    )
}


export default ViewAccounts;