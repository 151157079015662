import { error as errorAlert } from './toastr';
import { Button, Modal, Form } from 'react-bootstrap';
import { useEffect, useState, createElement } from 'react';


/**
 * A dialog (BS modal) that confirms 
 * Equivalent of JS function of confirm.
 * @param {{
 * as?: React.ReactNode,
 * handleAction: () => void,
 * btnText: string, 
 * btnClass: string,
 * confirmationText: string,
 * cancelBtnText: string, 
 * cancelButtonAction: () => void,
 * [x: string]: any,
 * children: any
 * }} param0 
*/
const ConfirmAction = ({
    as = Button,
    handleAction,
    confirmationText,
    btnText,
    btnClass,
    cancelBtnText,
    cancelButtonAction,
    children,
    ...props }
) => {

    const [show, setShow] = useState(false);

    const handleShow = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShow(true)
    }

    const handleClick = () => {
        setShow(false);
        return handleAction();
    }

    const handleCancel = () => {
        setShow(false);
        if (cancelButtonAction && typeof (cancelButtonAction) == 'function') {
            cancelButtonAction();
        }
    }

    return (
        <>
            {createElement(
                as,
                { ...props, onClick: handleShow },
                children
            )}

            <Modal show={show} onHide={() => setShow(false)} scrollable size="sm" backdrop="static" animation={false}>

                <Modal.Body>
                    <p className="text-center mb-1">
                        {confirmationText || `Are you sure?`}
                    </p>
                </Modal.Body>
                <Modal.Footer className="py-1 justify-content-around">
                    <Button variant="link" className="text-secondary text-uppercase font-weight-normal" onClick={handleCancel}>
                        {cancelBtnText || 'Cancel'}
                    </Button>
                    <Button variant="link" className={(btnClass || "text-success") + " text-uppercase font-weight-normal"} onClick={handleClick}>
                        {btnText || 'Yes'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


/**
 * Prompts a user to input a text before they can confirm anything or choose to cancel. 
 * Similar to JS native Prompt But uses bootstrap Modal
 * @param {{
 * as: ?React.ReactNode
 * onAccept: (text: string) => void,
 * btnText: string, 
 * btnClass: string,
 * promptMessage: ?string,
 * cancelBtnText: ?string, 
 * cancelButtonAction: ?() => void,
 * [x: string]: any,
 * children: any
 * }} props 
 */
const PromptAction = ({
    as = Button,
    onAccept,
    promptMessage,
    btnText,
    btnClass,
    cancelBtnText,
    cancelButtonAction,
    children,
    ...props
}) => {

    const [show, setShow] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        if (!show) setText('');
    }, [show])

    /**
     * Open the current modal
     * @param {React.MouseEvent} e 
     */
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true)
    }

    /**
     * 
     */
    const handleClick = () => {

        if (text === '') return errorAlert('Provide a message or cancel the operation.');

        setShow(false);
        onAccept(text);
    }

    /**
     * Handles the cancel and optionally runs the cancel action if the user has provided one.
     */
    const handleCancel = () => {
        setShow(false);

        if (cancelButtonAction && typeof (cancelButtonAction) == 'function') {
            cancelButtonAction();
        }
    }

    return (
        <>
            {createElement(
                as,
                { ...props, onClick: handleShow },
                children
            )}

            <Modal show={show} onHide={() => setShow(false)} scrollable centered backdrop="static" animation={false}>

                <Modal.Body>
                    <p className="mb-2">
                        {promptMessage || `Provide a message/description please.`}
                    </p>
                    <div className="mt-4 mb-2">
                        <Form.Control as="textarea"
                            placeholder="Type some text in here"
                            rows="3"
                            value={text}
                            onChange={e => setText(e.currentTarget.value)}
                            onFocus={e => e.currentTarget.setAttribute('rows', 8)}
                            onBlur={e => e.currentTarget.setAttribute('rows', 3)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-1 justify-content-around">
                    <Button variant="link" className="text-secondary text-uppercase font-weight-normal" onClick={handleCancel}>
                        {cancelBtnText || 'Cancel'}
                    </Button>
                    <Button
                        variant="link"
                        className={(btnClass || "text-success") + " text-uppercase font-weight-normal"}
                        onClick={handleClick}
                        disabled={text === ''}
                    >
                        {btnText || 'OK'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export { ConfirmAction, PromptAction };