// import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import cur from './currency';



/**
 * 
 * @typedef {{
 *  stamp_duty: number
 *  extra_title: number
 *  bank_charges: number
 *  mortgage_reg: number
 *  extra_deeds: number
 *  upcountry: number
 *  disbursements: number
 *  prof_fee: number
 *  vat: number
 *  misc: number 
 *  deb: number
 * }} ScheduleItemDef
 * 
 */


/**
 * @param {Object} props
 * @param {ScheduleItemDef} props.item
 * @param {() => void} props.onDelete
 */
const ScheduleItem = ({ item, onDelete }) => {


    return (
        <tr>
            <td>{item.title}</td>
            <td>{item.customer}</td>
            <td className='text-end'>{cur(item.loan_amount, 0).format()}</td>
            <td className='text-end'>
                <Button
                    variant='link'
                    size="sm"
                    className="text-danger float-right py-0"
                    onClick={onDelete}
                >
                    <i className='fas fa-trash-alt me-2' />Delete Item
                </Button>
            </td>
        </tr>
    )
}

export {
    ScheduleItem
}