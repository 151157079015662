import { useContext, useEffect, useState } from 'react';
import { Col, Row, Card, ListGroup, Button } from 'react-bootstrap';
import { Chart, registerables } from 'chart.js';
import { AppUserContext } from '../../App';
import CategoryTitlesVsTtTA from './banks/CategoryTitlesVsTtTA';
import FinanceSummary from './general/FinanceSummary';
import IndividualBankPerformance from './banks/IndividualBankPerformance';
// import IndividualUserPerformance from './rms/IndividualUserPerfomance';
// import QuarterlyUsersPerformance from './rms/QuarterlyUsersPerformance';
import { Link } from 'react-router-dom';
import { StatusCard } from '../../components/status-cards';
import './dashboard.scss';
import { getDashboardDetails } from '../../resources/api/users';
import moment from "moment";
import { ErrorLoading, PageLoading } from '../../components/loading';
import cur from '../../components/currency';
import { arrayAverage, sortFunction } from '../../components/resources';
import { useViewNo } from '../../components/pagination';
import { titleStatusActivity } from '../../assets/constants';


Chart.register(...registerables);
Chart.defaults.font.family = "'Fira Sans' sans-serif";
Chart.defaults.datasets.line.tension = .3;

const Dashboard = () => {

    const { profile } = useContext(AppUserContext);

    const [error, setError] = useState();
    const [loaded, setLoaded] = useState(false);

    const [feenotes, setFeenotes] = useState({});
    const [titles, setTitles] = useState({});

    const [dashdata, setDashData] = useState({
        feenotes: 0,
        no_titles: 0,
        no_overdue_titles: 0,
        turnaround_time: 0,
        overdue_titles: [],
        activity: []
    })

    const [start, page, no_pages, Back, Forward] = useViewNo(dashdata.overdue_titles.length, 4);
    const [starta, pagea, no_pagesa, Backa, Forwarda] = useViewNo(dashdata.activity.length, 5);

    useEffect(() => {
        getDashboardDetails()
            .then(({ dashboard }) => {
                let cur_date = moment(),
                    cur_year = parseInt(cur_date.format("Y")),
                    cur_month = parseInt(cur_date.format("M")),
                    min_month = cur_month - 12,
                    min_year = cur_year;

                if (min_month < 1) {
                    min_month = min_month + 12;
                    min_year = min_year - 1;
                }

                let _feenotes = {}, _titles = {};
                let account, key;
                let current_data = {
                    feenotes: [],
                    titles: [],
                    d_titles: [],
                    overdue_titles: []
                }

                dashboard.feenotes.forEach(note => {
                    const date = moment(note.feenote_date),
                        m = parseInt(date.format("M")),
                        y = parseInt(date.format('Y'));

                    if ((y < min_year) || (y === min_year & m < min_month)) return;

                    if (m === cur_month && y === cur_year) {
                        current_data.feenotes.push(note.total_amount)
                    }

                    key = `${y}_${m}`;
                    account = note.account_id;

                    if (!(account in _feenotes)) {
                        _feenotes[account] = {
                            title: dashboard.accounts.find(a => a.id === note.account_id).title,
                            feenotes: {}
                        }
                    }

                    if (!(key in _feenotes[account].feenotes)) {
                        _feenotes[account].feenotes[key] = []
                    }

                    _feenotes[account].feenotes[key].push(note.total_amount);

                });


                dashboard.titles.forEach(title => {
                    const date = moment(title.instruction_date),
                        m = parseInt(date.format("M")),
                        y = parseInt(date.format('Y'));




                    if (!!title.dispatch_date) {

                        const d_date = moment(title.dispatch_date),
                            d_m = parseInt(d_date.format("M")),
                            d_y = parseInt(d_date.format('Y'));

                        if (d_m === cur_month && d_y === cur_year) {
                            current_data.d_titles.push(title)
                        }

                        if (!title.return_date && (cur_date.diff(d_date, "days") > 7)) {
                            title.account = dashboard.accounts.find(a => a.id === title.account_id).title
                            current_data.overdue_titles.push(title);
                        }

                    }

                    if ((y < min_year) || (y === min_year && m < min_month)) return;

                    if (m === cur_month && y === cur_year) {
                        current_data.titles.push(title)
                    }

                    key = `${y}_${m}`;
                    account = title.account_id;

                    if (!(account in _titles)) {
                        _titles[account] = {
                            title: dashboard.accounts.find(a => a.id === title.account_id).title,
                            titles: {}
                        }
                    }

                    if (!(key in _titles[account].titles)) {
                        _titles[account].titles[key] = []
                    }

                    _titles[account].titles[key].push(title);
                });

                setFeenotes(_feenotes);
                setTitles(_titles);
                setDashData({
                    feenotes: current_data.feenotes.reduce((p, c) => p + c, 0),
                    no_titles: current_data.titles.length,
                    no_overdue_titles: current_data.d_titles
                        .filter(t => (cur_date.diff(moment(t.dispatch_date), "days") > 7 && !t.return_date))
                        .length,
                    turnaround_time: arrayAverage(current_data.d_titles.map(t => t.turnaround_time)),
                    overdue_titles: current_data.overdue_titles.sort((a, b) => sortFunction(a, b, 'dispatch_date', "desc")),
                    activity: dashboard.activity
                })
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))
    }, [])

    if (!loaded) return <PageLoading>Loading dashboard details...</PageLoading>

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <div className="dashboard">
            <h1 className="fw-bold">
                Hello {profile.display_name} <i className="far fa-hand-peace ms-3" />
            </h1>
            <p className="lead">
                Let's take a glance at things right now!
            </p>

            <h3 className="mt-4 mt-md-5 underlined fw-bold">
                So far, this month in figures
            </h3>

            <Row className="g-2">
                <Col lg={3} sm={6}>
                    <StatusCard
                        bg="success"
                        figure={cur(dashdata.feenotes).format()}
                        description="In Fee Notes Raised"
                        icon="fas fa-dollar-sign"
                    />
                </Col>
                <Col lg={3} sm={6}>
                    <StatusCard
                        bg="primary"
                        figure={dashdata.no_titles}
                        description="Titles Received"
                        icon="fas fa-hands"
                    />
                </Col>
                <Col lg={3} sm={6}>
                    <StatusCard
                        bg="danger"
                        figure={dashdata.no_overdue_titles}
                        description="Titles Overdue"
                        icon="fas fa-exclamation-triangle"
                    />
                </Col>
                <Col lg={3} sm={6}>

                    <StatusCard
                        bg="secondary"
                        figure={`${dashdata.turnaround_time} days`}
                        description="Average Turn Around"
                        icon="fas fa-exchange-alt"
                    />
                </Col>
            </Row>


            <Row className="upcoming">
                <Col xl={6} md={6} className="my-3">
                    <Card>
                        <Card.Header as="h4" className="py-3">
                            Recent Activity
                        </Card.Header>
                        <Card.Body>
                            <ListGroup variant="flush">

                                {dashdata.activity.slice(starta, starta + 5).map(a => (
                                    <ListGroup.Item>
                                        <div className="d-flex small text-muted justify-content-between mb-1 fw-bold">
                                            <span>
                                                {a.title}
                                            </span>
                                            <span>{moment(a.date_added).format("DD MMM YYYY")}</span>
                                        </div>
                                        <div>
                                            {titleStatusActivity[a.status]} by {a.display_name}
                                        </div>
                                    </ListGroup.Item>
                                ))}

                                <ListGroup.Item className='text-end pt-3'>
                                    <Button onClick={Backa} variant="outline-primary" size="sm">
                                        <i className="fas fa-hand-point-left" />
                                    </Button>
                                    <span className="small mx-2">Page {pagea} of {no_pagesa}</span>
                                    <Button onClick={Forwarda} variant="outline-primary" size="sm">
                                        <i className="fas fa-hand-point-right" />
                                    </Button>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6} md={6} className="my-3">
                    <Card>
                        <Card.Header as="h4" className="py-3">
                            Overdue Titles
                        </Card.Header>
                        <Card.Body>
                            <ListGroup variant="flush">
                                {dashdata.overdue_titles.slice(start, start + 4).map(t => (
                                    <ListGroup.Item key={t.id}>
                                        <div className="d-flex text-muted small justify-content-between">
                                            <span>
                                                {t.account}
                                            </span>
                                            <span>{moment(t.instruction_date).format('DD MMM YYYY')}</span>
                                        </div>
                                        <h6 className="mb-1">
                                            <Link to={`/app/titles/${t.id}`} className="stretched-link text-dark">
                                                {t.title}
                                            </Link>
                                        </h6>
                                        <div className="small text-secondary">
                                            Dispatched on {moment(t.dispatch_date).format('DD MMM YYYY')}
                                        </div>
                                    </ListGroup.Item>
                                ))}

                                <ListGroup.Item className='text-end pt-3'>
                                    <Button onClick={Back} variant="outline-primary" size="sm">
                                        <i className="fas fa-hand-point-left" />
                                    </Button>
                                    <span className="small mx-2">Page {page} of {no_pages}</span>
                                    <Button onClick={Forward} variant="outline-primary" size="sm">
                                        <i className="fas fa-hand-point-right" />
                                    </Button>
                                </ListGroup.Item>

                            </ListGroup>
                        </Card.Body>
                    </Card>

                </Col>

            </Row>

            <h3 className="mt-4 mt-md-5 underlined fw-bold">
                General performance in graphics
            </h3>
            <Row>
                <Col xl={12} className="my-4">
                    <FinanceSummary feenotes={feenotes} titles={titles} />
                </Col>

            </Row>

            <h3 className="mt-4 mt-md-5 underlined fw-bold">
                Account performance in graphics
            </h3>

            <Row>
                <Col xl={12} className="my-4">
                    <CategoryTitlesVsTtTA titles={titles} />
                </Col>


                <Col xl={12} className="my-4">
                    <IndividualBankPerformance titles={titles} feenotes={feenotes} />
                </Col>
            </Row>


            {/* <h3 className="mt-4 mt-md-5 underlined fw-bold">
                Manager performance in graphics
            </h3>

            <Row>
                <Col xl={12} className="my-4">
                    <QuarterlyUsersPerformance />
                </Col>
                <Col xl={12} className="my-4">
                    <IndividualUserPerformance />
                </Col>
            </Row> */}
        </div >
    )
}













export default Dashboard;

