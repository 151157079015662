import { useState, useEffect, useRef, createElement } from 'react';
import { Modal, Form, InputGroup as Group, Button, Col, ListGroup } from 'react-bootstrap';
import { sortFunction } from './resources';
import { error as errorAlert, success as successAlert } from './toastr';
import faker from 'faker';

const itemStyle = {
    whiteSpace: 'nowrap',
    padding: '.05rem .6rem',
    margin: '.25rem .25rem',
    display: 'inline-block',
    backgroundColor: 'var(--bs-secondary)',
    fontSize: '.8rem',
    borderRadius: '2rem'
}


/**
 * 
 * @param {Object} props
 * @param {string} props.variant
 * @param {"sm" | "lg"} props.size
 * @param {string} props.className
 * @param {(contact: {id: string, display_name: string, email: string, telephone: string}) => void} props.onUpload
 */
const ContactUpload = ({ onUpload, variant, size, children, className = "" }) => {

    const inputref = useRef();

    const { Row, Control, Label } = Form;
    const [show, setShow] = useState(false);
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const [details, setDetails] = useState({
        other_names: '', last_name: '', email: '', telephone: '', isCompany: false, company: ""
    })

    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);

        //Send data to the server and get the contact
        setTimeout(() => {
            form.reset();
            setHandlingSubmit(false);
            setShow(false);
            onUpload({ id: faker.random.uuid(), ...details, display_name: `${details.other_names} ${details.last_name}` });
            successAlert("Contact has been created.");
        }, 2000);
    }

    return (
        <>
            <Button variant={variant || "outline-secondary"} size={size} className={className} onClick={() => setShow(true)}>
                {children}
            </Button>

            <Modal show={show} onExit={() => setValidated(false)} centered animation={false}>
                <Modal.Body>
                    <Form noValidate validated={validated} ref={inputref}>
                        <Row>
                            <Col sm={6} className="my-1">
                                <Label className="form-field-title">First Name(s)</Label>
                                <Control
                                    placeholder="e.g., John"
                                    value={details.other_names}
                                    onChange={e => setDetails({ ...details, other_names: e.currentTarget.value })}
                                    required
                                />
                                <Control.Feedback type="invalid">
                                    Contact must have other names
                                </Control.Feedback>
                            </Col>
                            <Col sm={6} className="my-1">
                                <Label className="form-field-title">Last Name</Label>
                                <Control
                                    placeholder="e.g., Doe"
                                    value={details.last_name}
                                    pattern="[a-zA-Z0-9]+"
                                    onChange={e => setDetails({ ...details, last_name: e.currentTarget.value })}
                                    required
                                />
                                <Control.Feedback type="invalid">
                                    A surname (last name) must be provided and cannot have spaces or special characters.
                                </Control.Feedback>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="my-1">
                                <Label className="form-field-title">Email</Label>
                                <Control
                                    placeholder="e.g., john@doe.com"
                                    type="email"
                                    value={details.email}
                                    onChange={e => setDetails({ ...details, email: e.currentTarget.value })}
                                    required
                                />
                                <Control.Feedback type="invalid">
                                    A valid email address must be provided.
                                </Control.Feedback>
                            </Col>
                            <Col sm={6} className="my-1">
                                <Label className="form-field-title">Telephone</Label>
                                <Control
                                    placeholder="e.g., +256-773-123456"
                                    pattern="^\+?[1-9]{1}[0-9]{0,2}(-|\s)?([0-9]{2,4}(-|\s)?)+$"
                                    value={details.telephone}
                                    onChange={e => setDetails({ ...details, telephone: e.currentTarget.value })}
                                />
                                <Control.Feedback type="invalid">
                                    The telephone number should be in the international format.
                                </Control.Feedback>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="my-1">
                                <Label className="form-field-title">Company</Label>
                                <Control
                                    placeholder="e.g., Apple Inc"
                                    value={details.company}
                                    onChange={e => setDetails({ ...details, company: e.currentTarget.value })}
                                    required={details.isCompany}
                                />
                                <Control.Feedback type="invalid">
                                    If this is a company, the company name is required.
                                </Control.Feedback>
                            </Col>
                            <Col sm={6} className="my-1">
                                <Label className="form-field-title">Is this a Company?</Label>
                                <Form.Check type="switch"
                                    inline custom
                                    id="is-company"
                                    name="is-company"
                                    checked={details.isCompany}
                                    onChange={() => setDetails(d => ({ ...d, isCompany: !d.isCompany }))}
                                    label={details.isCompany ? "Yes" : "No"}
                                />
                                <Form.Text muted>
                                    If this is a company contact, the name provided would be the contact person for that company.
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row className="mt-3 text-right">
                            <Col>
                                <Button variant="success" className="px-3 m-1 rounded-pill" disabled={handlingSubmit} onClick={handleSubmit}>
                                    {handlingSubmit ? <i className="fas fa-circle-notch fa-spin mr-2"></i> : <i className="fas fa-thumbs-up mr-2"></i>}
                                    Upload Contact
                                </Button>
                                <Button variant="secondary" className="rounded-pill px-3 m-1" onClick={() => setShow(false)} disabled={handlingSubmit}>
                                    <i className="fas fa-times-circle mr-2"></i>Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

/**
 * @param {Object} props
 * @param {string} props.variant
 * @param {"sm" | "lg"} props.size
 * @param {number | null} props.maxContacts
 * @param {(contactids: string[]) => void} props.onChooseContacts
 * @param {{id: string, display_name: string, email: string, telephone: string}[]} props.conacts
 */
const ContactSelector = ({
    as = Button,
    variant = "secondary",
    size,
    className = "",
    children,
    maxContacts = 1,
    contacts = [],
    onChooseContacts,
    ...props
}) => {

    const [show, setShow] = useState(false);
    const { Control } = Form;

    const [filteredContacts, setFilteredContacts] = useState([]);
    const [displayContacts, setDisplayContacts] = useState([]);

    const [selected, setSelected] = useState([]);

    const [search, setSearch] = useState('');
    // const [filter, setFilter] = useState('');

    /**
     * 1. addFiles on finished
     */


    /**
     * on chnage of filter and search value
     */
    useEffect(() => {

        if (search.length < 2) return setDisplayContacts(filteredContacts);

        setDisplayContacts(
            [...filteredContacts
                .filter(
                    i => i.display_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                )
            ]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, JSON.stringify(filteredContacts)]);


    /**
     * on change of the length of the contacts, resort them.
     */
    useEffect(() => {
        const srtFn = (a, b) => sortFunction(a, b, 'display_name');
        setFilteredContacts(contacts.sort(srtFn));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts.length])

    /**
     * when hiding the modal, set selected to zero 
     * so that when someone comes back they can come back to no previously selected values
     */
    useEffect(() => {
        if (!show) setSelected([]);
    }, [show])


    /**
     * A a file to the list of seelcted files.
     * @param {string} id - ID of the file to add
     */
    const handleClick = id => {

        if (selected.indexOf(id) === -1) {

            if (selected.length >= (maxContacts || selected.length + 1))
                return errorAlert("You have selected the maximum number of contacts you are permitted to choose.");

            setSelected([...selected, id]);
        } else {
            setSelected([...selected.filter(i => i !== id)]);
        }

    }

    const submitContacts = () => {
        onChooseContacts(selected);
        setShow(false);
    }

    const handleOpenClick = e => {
        e.preventDefault();
        setShow(true);
    }

    /**
     * Remove file id from list of selected items
     * @param {React.MouseEvent} e 
     * @param {string} id 
     */
    const removeContact = (e, id) => {
        e.preventDefault();
        setSelected(s => ([...s.filter(i => i !== id)]))
    }

    return (
        <>
            {createElement(
                as,
                { ...props, variant: variant, className: className, size: size, onClick: handleOpenClick },
                children
            )}

            <Modal show={show} centered animation={false} scrollable backdrop="static">
                <Modal.Header className="pt-3 justify-content-between align-items-sm-center border-bottom-0 flex-column flex-sm-row">
                    <div>
                        {selected.length} contact(s) selected
                    </div>
                    <Form>
                        <Control size="sm"
                            placeholder="Search For Someone"
                            value={search}
                            onChange={e => setSearch(e.currentTarget.value)}
                        />
                    </Form>
                </Modal.Header>
                <Modal.Body>

                    <div className="mb-2">
                        {contacts
                            .filter(f => selected.indexOf(f.id) !== -1)
                            .map(f => (
                                <span style={itemStyle} className="text-white" key={f.id}>
                                    {f.display_name}
                                    <a href="#." className="ms-2 text-light" onClick={e => removeContact(e, f.id)}>
                                        <i className="fas fa-times" />
                                    </a>
                                </span>
                            ))}
                    </div>

                    <ListGroup>
                        {displayContacts.map(f => (
                            <ListGroup.Item key={f.id}
                                action
                                onClick={() => handleClick(f.id)}
                                active={selected.indexOf(f.id) !== -1}
                            >
                                {/* <div className="ml-3"> */}
                                <h5 className="mb-1 font-weight-normal">{f.display_name}</h5>
                                {f.email && <div><i className="far fa-envelope fa-fw mr-2" />{f.email}</div>}
                                {f.telephone && <div><i className="fas fa-phone fa-fw mr-2" />{f.telephone}</div>}
                                {f.company && <div><i className="far fa-building fa-fw mr-2" />{f.company}</div>}
                                {/* </div> */}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <span className="d-inline-block small me-auto text-muted">
                        {contacts.length} contacts
                    </span>
                    <Button
                        variant="success"
                        className="px-2 px-sm-3 m-1 rounded-pill"
                        disabled={selected.length === 0}
                        onClick={submitContacts}
                    >
                        <i className="fas fa-thumbs-up me-1 me-sm-2" />Add Selected
                    </Button>
                    <Button
                        variant="secondary"
                        className="rounded-pill px-2 px-sm-3 m-1"
                        onClick={() => setShow(false)}
                    >
                        <i className="fas fa-times-circle me-1 me-sm-2"></i>Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

/**
 * @param {Object} props
 * @param {string[]} props.value
 * @param {(value: string) => void} props.onChange
 * @param {string} props.placeholder
 * @param {string} props.errorText
 * @param {boolean} props.allowUpload
 * @param {number} props.maxContacts
 * @param {boolean} props.required
 * @param {{id:string, telephone: string, display_name: string, email: string, company: string|null}[]} props.contacts
 */
const ContactInput = ({
    value = [],
    onChange,
    placeholder,
    errorText,
    contacts: conts = [],
    allowUpload = true,
    maxContacts = 1,
    required = false
}) => {

    value = value.filter(v => v);

    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        setContacts(conts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conts.length])

    // 
    
    /**
     * handle upload of a contact.
     * @param {{id: string, display_name: string, email: string, telephone: string}} contact 
     */
    const handleContactUpload = contact => {

        if (maxContacts === 1) {
            onChange(contact.id);
        } else {
            onChange([...value, contact.id].join(","));
        }


        setContacts(c => [...c, contact]);
    }

    /**
     * Remove a contact from the selected list
     * @param {React.MouseEvent} e 
     * @param {string} contactid 
     */
    const removeContact = (e, contactid) => {
        e.preventDefault();
        onChange(value.filter(i => i !== contactid).join(","));
    }

    /**
     * Oon hit of the delete key in the input field then remove the item there.
     * @param {React.KeyboardEvent} e 
     */
    const handleKeyUp = e => {
        let isBackspace = false;

        if ('key' in e) {
            isBackspace = (e.key === 'Backspace');
        } else if ('keyCode' in e) {
            isBackspace = (e.keyCode === 8);
        }

        if (!isBackspace) return;

        onChange("");
    }


    const chosencontacts = contacts.filter(f => value.indexOf(f.id) !== -1);

    if (maxContacts === 1) {
        return (
            <Group>
                <Form.Control
                    placeholder={placeholder || "Search or upload a contact"}
                    value={chosencontacts.length > 0 ? chosencontacts[0].display_name : ""}
                    onChange={() => null}
                    onKeyUp={handleKeyUp}
                    required={required}
                />
                <Group.Append>
                    <ContactSelector
                        size="sm"
                        variant="link"
                        contacts={contacts.filter(i => value.indexOf(i.id) === -1)}
                        maxContacts={1}
                        onChooseContacts={cons => onChange(cons.join(""))}
                    >
                        <i className="fas fa-search text-secondary" />
                    </ContactSelector>
                </Group.Append>

                {(allowUpload && value.length === 0) &&
                    <Group.Append>
                        <ContactUpload
                            size="sm"
                            variant="link"
                            onUpload={val => handleContactUpload(val)}
                        >
                            <i className="fas fa-plus-circle text-secondary" />
                        </ContactUpload>
                    </Group.Append>
                }
                {required &&
                    <Form.Control.Feedback type="invalid">
                        {errorText || "A contact must be selected"}
                    </Form.Control.Feedback>}
            </Group>
        )
    }

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center">
                <div>
                    {value.length > 0 ? chosencontacts.map(f => (
                        <span style={itemStyle} className="text-white" key={f.id}>
                            {f.display_name}
                            <a href="#." className="ms-2 text-light" onClick={e => removeContact(e, f.id)}>
                                <i className="fas fa-times" />
                            </a>
                        </span>
                    )) : "No contacts selected"}
                </div>
                <div>

                    {(!maxContacts || (value.length < maxContacts)) &&
                        <ContactSelector size="sm"
                            variant="link"
                            contacts={contacts.filter(i => value.indexOf(i.id) === -1)}
                            maxContacts={maxContacts ? maxContacts - value.length : false}
                            onChooseContacts={cons => onChange([...value, ...cons].join(","))}
                        >
                            <i className="fas fa-search me-1" />Search
                        </ContactSelector>
                    }

                    {((!maxContacts || value.length < maxContacts) && allowUpload) &&
                        <ContactUpload size="sm" variant="link" onUpload={handleContactUpload}>
                            <i className="fas fa-plus-circle me-1" />Upload
                        </ContactUpload>
                    }

                </div>
            </div>

            {required &&
                <div>
                    <Form.Control value={value.join(",")} onChange={() => null} required className="d-none" />
                    <Form.Control.Feedback type="invalid">
                        {errorText || "At least one contact must be selected."}
                    </Form.Control.Feedback>
                </div>
            }
        </>
    )
}


export { ContactSelector, ContactUpload };
export default ContactInput;