import Menu from "./Menu";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Accounts from "./accounts/accounts";
import Titles from "./titles/titles";
import FeeNotes from "./fee-notes/feenotes";
import Users from "./users/users";
import Profile from "./profile/profile";
import { useContext, useEffect } from "react";
import { AppUserContext } from "../App";
import Expenses from "./expenses/expenses";






const SecuritiesApp = ({ logoutUser }) => {


    const { isLoggedIn } = useContext(AppUserContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            const path = encodeURIComponent(location.pathname);
            navigate(`/?redirect=${path}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, navigate])

    return (
        <>
            <Menu logoutUser={logoutUser} />
            <div className="content-wrapper px-1 px-md-3">
                <Routes>
                    <Route path="/me/*" element={<Profile />} />
                    <Route path="/titles/*" element={<Titles />} />
                    <Route path="/accounts/*" element={<Accounts />} />
                    <Route path="/fee-notes/*" element={<FeeNotes />} />
                    <Route path="/users/*" element={<Users />} />
                    <Route path="/expenses/*" element={<Expenses />} />
                    <Route path="/" element={<Dashboard />} />
                </Routes>
            </div>
        </>

    )
}

export default SecuritiesApp;