import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import New from './expense-manipulation';
import View from './view-expense';
import ViewAll from './view-expenses';

const Expenses = () => {

    const { profile } = useContext(AppUserContext);

    if ((profile.roles.indexOf("finance") === -1) && (profile.roles.indexOf("admin") === -1)) {
        return <ErrorLoading>You do not have the permission to access this module.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="new" element={<New />} />
            <Route path=":expenseid/*" element={<View />} />
            <Route path="/" element={<ViewAll />} />
        </Routes>
    )
};

export default Expenses;