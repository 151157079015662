

export const firmRoles = [
    "admin",
    "finance",
    "relationship-manager"
];

export const firmDetails = {
    tin: "1000911722",
    account_no: "0502000040"
}

export const sss = {
    stamp_duty: "Stamp Duty",
    extra_title: "Extra Deeds",
    bank_charges: "Bank Charges",
    mortgage_reg: "Mortgage Registration",
    extra_deeds: "Extra Deeds",
    upcountry: "Upcountry & Mortgage Removal",
    disbursements: "Disbursements",
    prof_fee: "Professional Fees",
    deb: "Debentures",
    misc: "Miscellaneous",
    vat: "VAT"
}

export const defaultScheduleItem = {
    stamp_duty: "",
    extra_title: "",
    bank_charges: "",
    mortgage_reg: "",
    extra_deeds: "",
    upcountry: "",
    disbursements: "",
    prof_fee: "",
    vat: "",
    misc: "",
    deb: "",
    total: 0
}

export const expenseCategories = [
    "allowances",
    "cleaning",
    "commissions",
    "electricity",
    "food",
    "gifts-and-donations",
    "internet",
    "petty Cash",
    "rent",
    "repairs",
    "salaries",
    "stationary",
    "statutory-dues",
    "training-and-seminars",
    "transport-facilitation",
    "water",
    "other"
]

export const paymentMethods = [
    "bank-transfer",
    "cash",
    "cheque",
    "credit-card",
    "direct-debit",
    "mobile-payment",
    "paypal"
]

export const expenseStatuses = [
    "non-billable",
    "unbilled",
    "invoiced"
];


export const titleStatuses = [
    "received",
    "awaiting-feenote",
    "feenote-created",
    "feenote-complete",
    "stampduty-paid",
    "dispatched",
    "repossessed",
    "returned",
    "unresolved"
]

export const titleStatusActivity = {
    "received": "Title was received",
    "awaiting-feenote": "Pushed for feenote creation",
    "feenote-created": "Feenote created",
    "feenote-complete": "Feenote completed & processed",
    "stampduty-paid": "Stamp duty paid",
    "dispatched": "Dispatched to land office",
    "repossessed": "Returned from land office",
    "returned": "Returned to client.",
    "unresolved": "Instruction could not be resolved."
}

export const titleDocumentCategories = [
    "instruction-letter",
    "feenote",
    "returning-letter",
    "other"
]

export const feeNoteSatuses = [
    "draft",
    "sent",
    "unpaid",
    "partially-paid",
    "paid",
    "voided",
    "cancelled"
];

export const fileIcons = {
    pdfs: "far fa-file-pdf",
    images: "fas fa-file-image",
    sheets: "fas fa-file-excel",
    docs: "fas fa-file-word",
    slides: "fas fa-file-powerpoint",
    videos: "fas fa-file-video",
    audios: "fas fa-file-audio",
}

export const graphColours = [
    {
        backgroundColor: 'rgba(25, 135, 84, 0.2)',
        borderColor: 'rgba(25, 135, 84, 1)',
    },
    {
        backgroundColor: 'rgba(255, 193, 7, 0.2)',
        borderColor: 'rgba(255, 193, 7, 1)'
    },
    {
        backgroundColor: 'rgba(32, 201, 151, 0.2)',
        borderColor: 'rgba(32, 201, 151, 1)',
    },
    {
        backgroundColor: 'rgba(214, 51, 132, 0.2)',
        borderColor: 'rgba(214, 51, 132, 1)',
    },
    {
        backgroundColor: 'rgba(102, 16, 242, 0.2)',
        borderColor: 'rgba(102, 16, 242, 1)',
    },
    {
        backgroundColor: 'rgba(13, 110, 253, 0.2)',
        borderColor: 'rgba(13, 110, 253, 1)'
    },
    {
        backgroundColor: 'rgba(253, 126, 20, 0.2)',
        borderColor: 'rgba(253, 126, 20, 1)',
    },
    {
        backgroundColor: 'rgba(13, 202, 240, 0.2)',
        borderColor: 'rgba(13, 202, 240, 1)'
    },
    {
        backgroundColor: 'rgba(111, 66, 193, 0.2)',
        borderColor: 'rgba(111, 66, 193, 1)',
    }

]



export {
    sss as ScheduleItemObject
}