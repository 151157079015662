import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, ListGroup, ListGroupItem, Table, Button } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { EditAccount } from "./account-manipulation";
import { StatusCard } from "../../components/status-cards";
import { createRMs, deleteRM, getAccount } from "../../resources/api/accounts";
import cur from "../../components/currency";
import moment from "moment";
import { sortFunction, UCWords } from "../../components/resources";
import { titleStatusActivity } from "../../assets/constants";
import { ConfirmAction } from "../../components/prompt";
import { errorAlert, successAlert } from "../../components/toastr";
import { Select } from "../../components/select";
import { getUsers } from "../../resources/api/users";
import { getTitles } from "../../resources/api/titles";
import { useViewNo } from "../../components/pagination";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/accounts").AccountObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import("../../resources/api/accounts").AccountObject>>} props.setDetails
 * @param {import("../titles/view-titles").TitleObject[]} props.titles
 */
const ViewDetails = ({ details, setDetails, titles = [] }) => {

    const nav_items = [
        { title: 'Accounts', href: "/app/accounts" },
        { title: details.title }
    ]
    const [users, setUsers] = useState([]);

    const users_to_select = useMemo(() => {

        const ids = details.relationship_managers.map(i => i.id);
        return users.filter(u => (ids.indexOf(u.contact_id) === -1));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(details.relationship_managers), JSON.stringify(users)])

    const handleRMDelete = (id) => {

        deleteRM(details.id, id)
            .then(({ message }) => {
                setDetails(d => ({ ...d, relationship_managers: d.relationship_managers.filter(i => i.__id !== id) }))
                successAlert(message);
            })
            .catch(e => errorAlert(e))
    }

    const handleRMAdd = ids => {
        createRMs(ids, details.id)
            .then(({ relationship_managers, message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, relationship_managers }))
            })
            .catch(e => errorAlert(e))
    }

    useEffect(() => {
        getUsers()
            .then(({ users }) => setUsers(users.map(m => ({
                ...m,
                title: m.display_name,
                id: m.contact_id
            }))))
            .catch(e => errorAlert(`Unable to load users hence cannot add relationship managers. Errors: ${e}`))
    }, [])


    const [start, page, no_pages, Back, Forward] = useViewNo(titles.length);

    // console.log(start, count);


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1200">
                <Title>{details.title}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Select
                            as={Btn}
                            title="Add Relationship Manager"
                            items={users_to_select}
                            maxItems={users_to_select.length}
                            onSelect={handleRMAdd}
                        >
                            <i className="fas fa-user-plus" />
                        </Select>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/accounts/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/accounts/new" title="New Account">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1200 mb-3 mb-md-5">

                <Row className="g-2">
                    <Col lg={3} sm={6}>
                        <StatusCard
                            bg="success"
                            figure={cur(details.total_feenote, 0).format()}
                            description="In Fee Notes Raised"
                            icon="fas fa-dollar-sign"
                        />
                    </Col>
                    <Col lg={3} sm={6}>
                        <StatusCard
                            bg="primary"
                            figure={details.no_titles}
                            description="Titles Received"
                            icon="fas fa-hands"
                        />
                    </Col>
                    <Col lg={3} sm={6}>
                        <StatusCard
                            bg="danger"
                            figure={details.no_pending_titles}
                            description="Titles Overdue"
                            icon="fas fa-exclamation-triangle"
                        />
                    </Col>
                    <Col lg={3} sm={6}>

                        <StatusCard
                            bg="secondary"
                            figure={`${details.average_turnaround} days`}
                            description="Average Turn Around"
                            icon="fas fa-exchange-alt"
                        />
                    </Col>
                </Row>

                <Row className="g-2">
                    <Col md={6}>
                        <Card className="my-2">
                            <Card.Header>
                                <h4 className="my-2">Details</h4>
                            </Card.Header>
                            <Card.Body>
                                <dl>
                                    <dt>Description</dt>
                                    <dd>
                                        {details.description || <span className="text-muted">None</span>}
                                    </dd>
                                    <dt>Feenotes created at end?</dt>
                                    <dd>
                                        {details.feenoteAtEnd ? "Yes" : "No"}
                                    </dd>
                                    <dt>Created On</dt>
                                    <dd>
                                        {moment(details.date_added).format("dddd DD MMMM YYYY")}
                                    </dd>
                                    <dt>Relationship Managers</dt>
                                    {(details.relationship_managers.length === 0) &&
                                        <span className="text-muted">No relationship managers</span>
                                    }
                                    {(details.relationship_managers.length > 0) &&
                                        <ListGroup variant="flush" style={{ maxWidth: "300px" }}>
                                            {details.relationship_managers.map(m => (
                                                <ListGroupItem key={m.id}>
                                                    {m.display_name}
                                                    <ConfirmAction
                                                        as="a"
                                                        href="#."
                                                        className="float-end text-danger"
                                                        confirmationText={`Are you sure you want to delete ${m.display_name} as a relationship manager for this ${details.title}?`}
                                                        btnClass="text-danger"
                                                        handleAction={() => handleRMDelete(m.__id)}

                                                    >
                                                        <i className="fas fa-times" />
                                                    </ConfirmAction>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>}
                                </dl>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="my-2">
                            <Card.Header>
                                <h4 className="my-2">Latest Activity</h4>
                            </Card.Header>
                            {(details.activity.length > 0) &&
                                <ListGroup variant="flush">
                                    {details.activity.slice(0, 5).map(a => (
                                        <ListGroup.Item key={a.id}>
                                            <div className="small d-flex justify-content-between text-muted">
                                                <Link to={`/app/titles/${a.title_id}`} >{a.title}</Link>
                                                <span>{a.display_name} | {moment(a.date_added).format("DD MMM YYYY, HH:mm")}</span>
                                            </div>
                                            <p className="mb-1">
                                                {titleStatusActivity[a.status]}
                                            </p>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>}

                            {(details.activity.length === 0) &&
                                <Card.Body>
                                    <p className="lead text-muted">
                                        No recent activity to display.
                                    </p>
                                </Card.Body>}
                        </Card>
                    </Col>
                </Row>

                <Row className="g-2">
                    <Col>
                        <Card className="my-2">
                            <Card.Header className="justify-content-between d-flex align-items-center">
                                <h4 className="my-2">Titles</h4>
                                <Link
                                    className="btn btn-sm btn-secondary rounded-pill"
                                    to={`/app/titles/new?account=${details.id}`}
                                >
                                    <i className="fas fa-plus-circle me-1" />New Title
                                </Link>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive hover style={{ minWidth: "800px" }} >
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Instruction Date</th>
                                            <th>Customer</th>
                                            <th>Land Office</th>
                                            <th className="text-end">Loan Amount</th>
                                            <th className="text-end">Fee Note</th>
                                            <th>Return Date</th>
                                            <th>Turn Around</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {titles.slice(start, start + 15).map(t => (
                                            <tr key={t.id}>
                                                <td>
                                                    <Link to={`/app/titles/${t.id}`} >{t.title}</Link>
                                                </td>
                                                <td>{moment(t.instruction_date).format("DD MMM YYYY")}</td>
                                                <td>{t.customer}</td>
                                                {/* <td>54TG45/54G4G</td> */}
                                                <td>{t.land_office}</td>
                                                <td className="text-end">{cur(t.loan_amount).format()}</td>
                                                <td className="text-end">
                                                    {!!t.feenote ? cur(t.feenote_amount).format() : <span className="text-muted">n/a</span>}
                                                </td>
                                                <td>
                                                    {!!t.return_date ? moment(t.return_date).format("DD MMM YYYY") : <span className="text-muted">n/a</span>}
                                                </td>
                                                <td>
                                                    {!!t.turnaround_time ? t.turnaround_time : <span className="text-muted">n/a</span>}
                                                </td>
                                                <td>{UCWords(t.status.replace(/-/g, " "))}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <div className="text-end">
                                    <Button onClick={Back} variant="outline-primary" size="sm">
                                        <i className="fas fa-hand-point-left" />
                                    </Button>
                                    <span className="small mx-2">Page {page} of {no_pages}</span>
                                    <Button onClick={Forward} variant="outline-primary" size="sm">
                                        <i className="fas fa-hand-point-right" />
                                    </Button>
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

        </>
    )

}


const AccountDetails = () => {

    const { accountid } = useParams();
    const [details, setDetails] = useState({});
    const [titles, setTitles] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [err, setError] = useState();

    useEffect(() => {


        setLoaded(false);

        getAccount(accountid, ["relationship_managers", "contact_persons", "activity"])
            .then(({ account }) => {
                account.activity.sort((a, b) => sortFunction(a, b, "date_added", "desc"));
                setDetails(account);
            })
            .catch(err => setError(err))
            .finally(() => setLoaded(true))

    }, [accountid])


    useEffect(() => {

        setTitles([]);

        getTitles(true, { account_id: accountid })
            .then(({ titles }) => {
                titles.sort((a, b) => sortFunction(a, b, "instruction_date", "desc"))
                setTitles(titles);
            })
            .catch(e => errorAlert(`Could not load titles for this account. ${e}`))

    }, [accountid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading account details ...</PageLoading>
    }

    if (err) return <ErrorLoading>{err}</ErrorLoading>

    return (
        <Routes>
            <Route path="/edit" element={<EditAccount details={details} setAccount={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} titles={titles} />} />
        </Routes>
    )

}


export default AccountDetails;