
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import cur from "../../../components/currency";
import { UCWords } from "../../../components/resources";



/**
 * 
 * @param {{
 *  titles: import("../view-titles").TitleObject[]
 * }} param0 
 * @returns 
 */
const ViewTitles = ({ titles = [] }) => {



    return (
        <Table>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Customer</th>
                    <th>Registered Proprieter</th>
                    <th className="text-end">Loan Amount</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {titles.map(t => (
                    <tr key={t.id}>
                        <td><Link to={`/app/titles/${t.id}`}>{t.title}</Link></td>
                        <td>{t.customer}</td>
                        <td>{t.registered_proprietor}</td>
                        <td className="text-end">{cur(t.loan_amount, 0).format()}</td>
                        <td>{UCWords(t.status.replace(/-/g, " "))}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default ViewTitles;