import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn } from '../../components/pageheader';
import { Form, Table, Card, Row, Col } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import { Link } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import moment from 'moment';
import { sortFunction, UCWords } from "../../components/resources";
import cur, { UGX } from '../../components/currency';
import { getTitles } from '../../resources/api/titles';
import { ErrorLoading, PageLoading } from '../../components/loading';


/**
 * @typedef {{
 *  id: string
 *  file_id: string, 
 *  title_id: string, 
 *  category: string, 
 *  file_title: string
 *  file_group: string
 *  file_slug: string
 *  display_name: string
 *  added_by: string
 * }} TitleDocObject
 */

/**
 * 
 * @typedef {{
 * id: string
 * title: string
 * batch_ref: string
 * instruction_date: string
 * dispatch_date: string|null
 * return_date: string|null
 * account_id: string
 * account: string
 * instrument_no: number
 * transaction_type: string
 * county: string
 * block: string
 * plot: string
 * volume: string
 * folio: string
 * land_type: string
 * customer: string
 * registered_proprietor: string
 * loan_amount: number
 * land_office: string
 * feenote_id: string
 * fundsReceived: number
 * turnaround_time: number
 * status: string
 * isOverdue: boolean
 * date_added: string
 * added_by: string
 * documents: TitleDocObject[]
 * feenote: string|import('../../resources/api/fee-notes').NoteObject,
 * notes: import('../../resources/api/titles').NoteObject[]
 * }} TitleObject 
 */

/**
 * View table items on screens larger than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {TitleObject[]} props.items
 * @param {number} props.currentPage
 * @param {number} props.noPageItems
*/
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {

    const El = ({ field, children, ...props }) => <th {...props} style={{ whiteSpace: "noWrap" }}>{children} <SortButton field={field} {...srt} handleSort={handleSort} /></th>;

    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            <colgroup>
                {/* <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "25%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "16%" }} />
                <col span="1" style={{ width: "16%" }} /> */}
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <El field="title">Title</El>
                    <El field="batch_ref">Batch</El>
                    <El field="account">Account</El>
                    <El field="instruction_date">Date</El>
                    <El field="return_date">Returned</El>
                    <El field="customer">Customer</El>
                    {/* <El field="registered_proprietor">Proprietor</El> */}
                    <El field="county">County</El>
                    <El field="land_office">Land Office</El>
                    <El field="loan_amount">Loan Amount</El>
                    <El field="turnaround_time">TA</El>
                    <El field="status">Status</El>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i} style={{ backgroundColor: e.isOverdue ? "rgba(255,0,0,0.125)" : "inherit" }}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={`/app/titles/${e.id}`}>{e.title}</Link>
                        </td>
                        <td>{e.batch_ref || 'n/a'}</td>
                        <td><Link to={`/app/accounts/${e.account_id}`}>{e.account}</Link></td>
                        <td>{moment(e.instruction_date).format("DD MMM YYYY")}</td>
                        <td>{e.return_date ? moment(e.return_date).format("DD MMM YYYY") : "n/a"}</td>
                        <td>{e.customer}</td>
                        {/* <td>{e.registered_proprietor}</td> */}
                        <td>{e.county}</td>
                        <td>{e.land_office}</td>
                        <td className="text-end">{cur(e.loan_amount, 0).format()}</td>
                        <td>{e.turnaround_time}</td>
                        <td>
                            {e.isOverdue ?
                                <span className='text-danger'>Overdue</span> :
                                UCWords(e.status.replace(/-/g, " "))}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {TitleObject[]} props.items
 */
const ViewMobile = ({ srt, handleSort, items }) => {

    const El = ({ field, children }) => (
        <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
            {children} <SortButton field={field} {...srt} handleSort={handleSort} />
        </span>
    )
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <El field="title">Title</El>
                <El field="batch_ref">Batch</El>
                <El field="county">County</El>
                <El field="instrument_no">instrument No</El>
                <El field="customer">Customer</El>
                <El field="registered_proprieter">Proprietor</El>
                <El field="loan_amount">Loan Amount</El>
                <El field="feenote_amount">Feenote</El>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-2">
                            {e.isOverdue ?
                                <span className="text-danger font-weight-normal text-uppercase">Overdue</span> :
                                <span className="text-secondary font-weight-normal text-uppercase">{e.status.replace(/-/g, " ")}</span>}
                            <span className="text-muted font-weight-normal">{moment(e.instruction_date).format("DD MMM YYYY")}</span>
                        </div>

                        <div className="lead mb-2">
                            <Link to={`/app/titles/${e.id}`} className="stretched-link">{e.title}</Link>
                        </div>
                        <h6>{UGX(e.loan_amount).format()}</h6>
                        <Row className="gx-1">
                            <Col xs={4} className="small">
                                Batch
                            </Col>
                            <Col xs={8}>
                                {e.batch_ref || 'n/a'}
                            </Col>
                        </Row>
                        <Row className="gx-1">
                            <Col xs={4} className="small">
                                Account
                            </Col>
                            <Col xs={8}>
                                {e.account}
                            </Col>
                        </Row>
                        <Row className="gx-1">
                            <Col xs={4} className="small">
                                Customer
                            </Col>
                            <Col xs={8}>
                                {e.customer}
                            </Col>
                        </Row>
                        <Row className="gx-1">
                            <Col xs={4} className="small">
                                Proprietor
                            </Col>
                            <Col xs={8}>
                                {e.registered_proprietor}
                            </Col>
                        </Row>
                        <Row className="gx-1">
                            <Col xs={4} className="small">
                                Land Office
                            </Col>
                            <Col xs={8}>
                                {e.land_office}
                            </Col>
                        </Row>
                        <Row className="gx-1">
                            <Col xs={4} className="small">
                                Returned
                            </Col>
                            <Col xs={8}>
                                {!!e.turnaround_time ?
                                    <>
                                        {moment(e.return_date).format("dd DD MMM YYYY")} ({e.turnaround_time} days)
                                    </> :
                                    <span className='text-muted'>n/a</span>}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}

/**
 * module that handles view of accounts
 * match path /app/accounts
 */
const ViewTitles = () => {

    const navItems = [{ title: 'Titles' }];

    const [srt, setSrt] = useState({
        currentField: 'instruction_date',
        currentOrder: 'desc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'title' });

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => !!i[f] ? i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1 : false)]);
    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        getTitles(true)
            .then(({ titles }) => {
                setOriginalItems(titles);
                setItems(titles);
            })
            .catch(err => setError(err))
            .finally(() => setLoaded(true))
    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder);

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    if (!loaded) return <PageLoading>Loading titles ... </PageLoading>

    if (error) return <ErrorLoading>{error}</ErrorLoading>


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1200">
                <Title>Titles</Title>
                <ActionBar>
                    <Btn href="/app/titles/new">
                        <i className="fas fa-plus-circle me-1" />New Title
                    </Btn>
                </ActionBar>
            </PageHeader>

            <div className="max-1200">
                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="title">Title</option>
                            <option value="batch_ref">Batch</option>
                            <option value="account">Account</option>
                            <option value="status">Status</option>
                            <option value="instruction_date">Instruction Date</option>
                            <option value="customer">Customer</option>
                            <option value="registered_proprieter">Proprieter</option>
                            <option value="county">County</option>
                            <option value="land_office">Land Office</option>
                            <option value="added_by">Added By</option>
                        </Form.Select>
                    </Col>


                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>
        </>
    )
}


export default ViewTitles;