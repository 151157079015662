
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from './../../components/pageheader';
import AccountForm from './account-form';

const accountObject = {
    title: "", description: "", feenoteAtEnd: false, contact_persons: [], relationship_managers: []
}

const NewAccount = () => {

    const nav_items = [
        { title: 'Accounts', href: '/app/accounts' },
        { title: 'New Account' }
    ];

    const handleSubmit = (details) => {
        console.log(details);
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>Create New Account</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <AccountForm
                    details={accountObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('./view-accounts').AccountObject} props.details 
 * @param {(value: React.SetStateAction<{}>) => void} props.setAccount
 */
const EditAccount = ({ details, setAccount }) => {

    const nav_items = [
        { title: 'Accounts', href: '/app/accounts' },
        { title: details.title, href: `/app/accounts/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setAccount(d => ({ ...d, ...details }));
        navigate(`/app/accounts/${details.id}`);
    }


    return (

        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.title}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <AccountForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewAccount, EditAccount };