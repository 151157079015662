import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap";
import { Chart, registerables } from 'chart.js';
import moment from "moment";
import { arrayAverage } from "../../../components/resources";

Chart.register(...registerables);



const IndividualBankPerformance = ({ feenotes = {}, titles = {} }) => {


    const [banks, setBanks] = useState(
        Object.keys(titles).map(k => ({ id: k, title: titles[k].title }))
    );

    const [selected, setSelected] = useState(banks[0]?.id);

    const [data, setData] = useState({
        feenotes: [],
        no_titles: [],
        turnaround_times: []
    });

    const [labels, setLabels] = useState([]);



    useEffect(() => {

        setBanks(
            Object.keys(titles).map(k => ({ id: k, title: titles[k].title }))
        );


        let m = parseInt(moment().format("M")) - 12,
            y = parseInt(moment().format("y"));

        if (m < 1) {
            m += 12;
            y -= 1;
        }

        const months = [];
        let mm, yy;

        for (let i = 1; i <= 12; i++) {
            mm = m + i;
            yy = y;

            if (mm > 12) {
                mm -= 12;
                yy += 1;
            }
            months.push({
                key: `${yy}_${mm}`,
                title: moment([y, mm - 1]).format("MMM")
            });
        }


        const _feenotes = months.map(m => {

            if (!(selected in feenotes)) return 0;

            let account = feenotes[selected];

            if (m.key in account.feenotes) {
                return account.feenotes[m.key].reduce((p, c) => p + c, 0)
            } else {
                return 0;
            }
        })


        const _titles = months.map(m => {

            if (!(selected in titles)) return { avg: 0, count: 0 };

            let account = titles[selected];

            if (!(m.key in account.titles)) return { avg: 0, count: 0 };

            return {
                count: account.titles[m.key].length,
                avg: arrayAverage(
                    account.titles[m.key]
                        .map(i => i.turnaround_time)
                        .filter(v => (v > 0))
                )
            }
        })

        setData({
            feenotes: _feenotes,
            no_titles: _titles.map(t => t.count),
            turnaround_times: _titles.map(t => t.avg)
        });

        setLabels(months.map(m => m.title))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(feenotes), JSON.stringify(titles), selected])

    const onDPChange = key => setSelected(key);


    return (
        <Card>
            <Card.Header className="d-flex align-items-center justify-content-between py-3">
                <h4 className='my-0'>Individual Account Performance</h4>

                {(banks.length > 0) &&
                    <span>
                        <Dropdown as={ButtonGroup} className="me-3" align="end">
                            <Button variant="outline-secondary">
                                {banks.find(b => b.id === selected).title}
                            </Button>
                            <Dropdown.Toggle split variant="secondary" />
                            <Dropdown.Menu>
                                {banks.map((b) =>
                                    <Dropdown.Item
                                        onClick={() => onDPChange(b.id)}
                                        eventKey={b.id} key={b.id}
                                    >
                                        {b.title}
                                    </Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <span className="font-weight-normal">25</span>
                    <span className="text-success ms-3">
                        <i className="fas fa-arrow-up small" /> 5.00%
                    </span> */}
                    </span>
                }
            </Card.Header>
            <Card.Body>
                <div style={{ maxHeight: '600px' }}>
                    <BankPerformanceChart data={data} labels={labels} />
                </div>
            </Card.Body>
        </Card>
    )
}


const BankPerformanceChart = ({ data, labels }) => {

    const canvasElement = useRef();


    useEffect(() => {
        const chart = new Chart(canvasElement.current, {
            type: 'bar',
            data: {
                labels,
                datasets: [{
                    label: 'Fee Notes',
                    data: data.feenotes,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                    yAxisID: 'yAxis1',
                    categoryPercentage: 0.95
                },
                {
                    label: 'No Titles',
                    type: "line",
                    data: data.no_titles,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    yAxisID: 'yAxis2',
                    categoryPercentage: 0.95
                },
                {
                    label: 'Turn Around Time',
                    type: "line",
                    data: data.turnaround_times,
                    backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    borderWidth: 1,
                    yAxisID: 'yAxis3',
                    categoryPercentage: 0.95
                }]
            },
            options: {
                scales: {
                    yAxis1: {
                        stacked: true,
                        title: {
                            display: true,
                            text: "Amount (UGX)"
                        }
                    },
                    yAxis2: {
                        beginAtZero: true,
                        position: "right",
                        title: {
                            display: true,
                            text: "Title Count"
                        },
                        grid: { drawOnChartArea: false }
                    },
                    yAxis3: {
                        beginAtZero: true,
                        position: "right",
                        title: {
                            display: true,
                            text: "Turn Around (Days)"
                        },
                        grid: { drawOnChartArea: false }
                    }
                }
            }
        });

        return () => chart.destroy();
    })

    return <canvas ref={canvasElement} width="100%" />
}


export default IndividualBankPerformance;