import { Form, Row, Col, FormSelect, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { error as errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { DateSelector } from '../../components/datetimepicker';
import moment from 'moment';
import FeeNoteItems from '../../components/fee-notes';
import { ScheduleItem } from '../../components/feenote-schedule';
import { Select } from '../../components/select';
import { getAccounts, getTitlesAwaitingFeenotes } from '../../resources/api/accounts';
import { sortFunction } from '../../components/resources';
import { getTitles } from '../../resources/api/titles';
import { createFeeNote, updateFeeNote } from '../../resources/api/fee-notes';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating fee note
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/fee-notes').NoteObject} props.details
 * @param {(details: import('../../resources/api/fee-notes').NoteObject) => void} props.onSubmit
 */
const FeeNoteForm = ({ details: feenoteObject, action, onSubmit }) => {


    const [details, setDetails] = useState(feenoteObject);
    const [schItems, setSchItems] = useState(feenoteObject.schedule_items || []);
    const [deleted_sch_items, setDeletedSchItems] = useState([]);
    const [deleted_items, setDeletedItems] = useState([]);
    const [items, setItems] = useState(feenoteObject.items || []);
    const [accounts, setAccounts] = useState([]);
    // const [notes, setNotes] = useState(feenoteObject.notes || []);

    const [titles, setTitles] = useState([]);
    const [selectedTitles, setSelectedTitles] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);



    /**
     * Delete an item from the scheduled stuff.
     * @param {number} idx 
     */
    const deleteItem = idx => {
        const _item = { ...schItems[idx] };

        setSelectedTitles(titles => titles.filter(t => t !== _item.title_id));
        setSchItems(schItems.filter((e, i) => i !== idx));
        if (!!_item.id) {
            setDeletedSchItems(d => d.concat(_item.id));
        }
    }

    /**
     * 
     * @param {string} title The title of the thing. 
     * @param {string} title_id ID of the title
     */
    const addItem = (title, title_id, customer, loan_amount) => {
        const _item = { title_id, title, customer, loan_amount, isNew: true }
        setSelectedTitles(titles => titles.concat(title_id));
        setSchItems(schItems => schItems.concat(_item));
    }


    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        if (schItems.length === 0) {
            errorAlert("You must have at least one title attached to this fee note.");
            return;
        }

        if (items.length === 0) {
            errorAlert("You must have at least one line item attached to this fee note.");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;
        const loan_amount = selectedTitles.map(t => titles.find(tt => tt.id === t)).reduce((p, c) => p + parseFloat(c.loan_amount), 0);

        if (action === "create") {
            promise = createFeeNote({
                ...details,
                schedule_items: schItems,
                items,
                loan_amount
            })
        } else {
            promise = updateFeeNote({
                ...details,
                new_schedule_items: schItems.filter(i => i.isNew),
                deleted_schedule_items: deleted_sch_items,
                new_items: items.filter(i => i.isNew),
                deleted_items,
                updated_items: items.filter(i => (!!i.id && i.hasChanged)),
                loan_amount
            }, details.id);
        }

        promise
            .then(({ message, feenote }) => {
                successAlert(message);
                onSubmit(feenote);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    useEffect(() => {

        if (action === "create") setSchItems([]);

        if (!details.account_id) {

            if (action === "create") {
                return setTitles([]);
            } else {
                return;
            }
        }

        getTitlesAwaitingFeenotes(details.account_id)
            .then(({ titles }) => {
                setTitles(prev_titles => [...prev_titles, ...titles]);

                if (titles.length === 0) {
                    return infoAlert("This account has no titles that are pending fee notes.");
                }

                successAlert("Titles awaiting fee notes for this account have been loaded.");
            })
            .catch(e => errorAlert(`Unfortunately you cannot proceed with this creation. Unable to load titles. Error: ${e}`))


    }, [details.account_id, action])


    useEffect(() => {
        getAccounts()
            .then(({ accounts }) => {
                setAccounts(accounts.sort((a, b) => sortFunction(a, b, "title")));
                infoAlert("Loaded all accounts");
            })
            .catch(e => errorAlert(`Could not load the accounts. Error: ${e}`))
    }, [])

    useEffect(() => {


        if (action !== "edit") return;

        const title_ids = feenoteObject.schedule_items.map(i => i.title_id);

        getTitles(false, { id: title_ids })
            .then(({ titles }) => {
                setTitles(prev_titles => [...prev_titles, ...titles]);
                setSelectedTitles(titles.map(t => t.id));
            })
            .catch(e => errorAlert(`Unfortunately you cannot proceed with this edit. Unable to load titles. Error: ${e}`))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, JSON.stringify(feenoteObject.schedule_items)])

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Account</Label>
                    <FormSelect
                        value={details.account_id}
                        onChange={e => setDetails({ ...details, account_id: e.currentTarget.value })}
                        disabled={action === "edit"}
                        required
                    >
                        <option value="">Select an account</option>
                        {accounts.map(a => <option key={a.id} value={a.id}>{a.title}</option>)}
                    </FormSelect>
                    <Feedback type="invalid">
                        Choose an account.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Fee Note Date</Label>
                    <DateSelector
                        value={details.feenote_date}
                        onChange={val => setDetails(d => ({ ...d, feenote_date: val }))}
                        required={true}
                        minDate={moment().subtract(1, "m").format("YYYY-MM-DD")}
                        maxDate={0}
                    />
                    <Feedback type="invalid">
                        Fee Note date is required.
                    </Feedback>
                </Col>
            </Row>


            <Row className="g-2">
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Instruction Details</Label>
                    <Control
                        as="textarea"
                        value={details.instruction}
                        onChange={e => setDetails({ ...details, instruction: e.currentTarget.value })}
                        required={true}
                        style={{ height: "100px" }}
                        onFocus={e => e.currentTarget.style.height = "300px"}
                        onBlur={e => e.currentTarget.style.height = "100px"}
                    />
                    <Feedback type="invalid">
                        The instruction details must be provided.
                    </Feedback>
                </Col>
            </Row>


            <h4 className="form-section-label">Titles</h4>

            <Table size='sm' hover responsive style={{ minWidth: "500px" }}>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Customer</th>
                        <th className='text-end'>Loan Amount</th>
                        <th>...</th>
                    </tr>
                </thead>
                <tbody>
                    {schItems.map((item, idx) => (
                        <ScheduleItem
                            key={idx}
                            item={item}
                            onDelete={() => deleteItem(idx)}
                        />
                    ))}
                </tbody>
            </Table>

            <Row className="g-2">
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Select
                            variant='secondary'
                            size="sm"
                            className='rounded-pill'
                            items={titles
                                .filter(i => selectedTitles.indexOf(i.id) === -1)
                                .map(t => ({ ...t, description: `Batch: ${t.batch_ref || 'n/a'} | Customer: ${t.customer} | Created on ${moment(t.instruction_date).format("DD MMM YYYY")}` }))}
                            maxItems={10}
                            onSelect={ids => ids.forEach(id => {
                                const _title = titles.find(t => t.id === id);
                                addItem(_title.title, _title.id, _title.customer, _title.loan_amount);
                            })}
                        >
                            <i className="fas fa-plus-circle me-2" />Add Title
                        </Select>
                    </div>

                </Col>
            </Row>


            <h4 className="form-section-label">Fee Note Line Items</h4>
            <Form.Text>
                Any items that are not directly included in the schedule.
                Please not that items added here are at fee-note level, not at the Title level.
            </Form.Text>

            <FeeNoteItems items={{ items, setItems }} deletedItems={{ deleted_items, setDeletedItems }} />


            {/* <h4 className="form-section-label">Notes</h4>
            <Notes {...{ notes, setNotes }} /> */}



            <Row>
                <Col className="mt-5 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Fee Note" : "Create Fee Note"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}







export default FeeNoteForm;