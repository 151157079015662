import { useState, useCallback, createContext, useEffect } from "react";
import {
    Routes,
    Route,
    BrowserRouter as Router
} from "react-router-dom";
import Login from "./home/login";
import SecuritiesApp from "./app/app";
import { getAuthStatus } from "./resources/api/auth";
import { infoAlert } from "./components/toastr";
import { PageLoading } from "./components/loading";
import ActivateAccount from "./home/activate";

/**
 * Context containing information about the user profile including, app accesses etc.
 * @type {React.Context<{profile: import("./resources/api/auth").AuthObject, isLoggedIn: boolean}>}
 */
const AppUserContext = createContext();



/**
 * Main App
 */
const App = () => {

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [profile, setProfile] = useState({});
    const [loaded, setLoaded] = useState(false);

    const loginUser = useCallback((details) => {
        setProfile(details);
        setLoggedIn(true);
    }, [])

    const logoutUser = useCallback(() => {
        setProfile({ roles: [] });
        setLoggedIn(false);
    }, [])

    useEffect(() => {
        getAuthStatus()
            .then(({ user_details }) => {
                setProfile(user_details);
                setLoggedIn(true)
            })
            .catch(() => {
                infoAlert("Please log in to continue");
                setLoggedIn(false);
            })
            .finally(() => setLoaded(true))
    }, [])

    if (!loaded) {
        return (
            <PageLoading>
                <div className="text-center">
                    <h4>NASSUUNA &amp; CO ADVOCATES </h4>
                    <br />
                    <h3>SECURITIES MANAGEMENT SYSTEM</h3>
                </div>


            </PageLoading>
        )
    }


    return (
        <AppUserContext.Provider value={{ profile, isLoggedIn }} >
            <Router>
                <Routes>
                    <Route
                        path="/app/*"
                        element={<SecuritiesApp logoutUser={logoutUser} />}
                    />
                    <Route
                        path="/activate"
                        element={<ActivateAccount loginUser={loginUser} />}
                    />
                    <Route
                        path="/"
                        element={<Login loginUser={loginUser} />}
                    />
                </Routes>
            </Router>
        </AppUserContext.Provider>
    );
}

export { AppUserContext };
export default App;