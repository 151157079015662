import { Routes, Route } from "react-router-dom"
import AccountDetails from "./account-details";
import { NewAccount } from "./account-manipulation";
import ViewAccounts from "./view-accounts";

const Accounts = () => {

    return (
        <Routes>
            <Route path="/new" element={<NewAccount />} />
            <Route path="/:accountid/*" element={<AccountDetails />} />
            <Route path="/" element={<ViewAccounts />} />
        </Routes>
    )
}

export default Accounts;