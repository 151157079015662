import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppUserContext } from '../App';
import { errorAlert, infoAlert, successAlert } from '../components/toastr';
import { logoutUser } from '../resources/api/auth';

/**
 * @param {Object} params
 * @param {string} params.title
 * @param {string} params.link
 * @param {boolean} params.active
 */
const NavItem = ({ title, link, children, active }) => (
    <li className={"nav-item" + (active ? " active" : "")}>
        <Link to={link} className="nav-link" title={title}>
            {children}
            <span className="nav-text">{title}</span>
        </Link>
    </li>
)

/**
 * @param {Object} params
 * @param {string} params.to
 * @param {boolean} params.active
 */
const MobileNavItem = ({ to, active, children }) => (
    <Link className={"flex-fill text-center nav-link" + (active ? " active" : "")} to={to}>
        {children}
    </Link>
)

/**
 * menu of the app
 */
const Menu = ({ logoutUser: signOut }) => {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuExpanded, setMenuExpanded] = useState(false);
    const [active, setActive] = useState('');

    const { profile } = useContext(AppUserContext);
    const navigate = useNavigate();

    const location = useLocation();

    const handleClick = e => {
        e.preventDefault();
        setMenuOpen(d => !d);
    }

    const handleExpand = e => {
        e.preventDefault();
        setMenuExpanded(d => !d);
    }

    const handleLogout = (e) => {
        e.preventDefault();
        infoAlert("Logging you out, please hold...");
        logoutUser()
            .then(({ message }) => {
                successAlert(message);
                signOut();
                navigate("/");
            })
            .catch((err) => errorAlert(err))
    }

    /**
     * change the status of the side nav whenever toggled. 
     */
    useEffect(() => {
        if (!isMenuExpanded) {
            document.body.classList.add('sidenav-toggled')
        }
        else document.body.classList.remove('sidenav-toggled');
    }, [isMenuExpanded])

    /**
     * close the nav whenver a navigation takes place.
     */
    useEffect(() => {
        setActive(location.pathname.split('/')[2] || '');
        setMenuOpen(false);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [location.pathname])

    /**
     * addition class when toggled. 
     */
    const additionalClass = isMenuOpen ? ' toggled' : '';

    const imgsrc = profile.image ?
        `/images/${profile.image}?w=64&h=64` :
        `https://eu.ui-avatars.com/api/?name=${profile.other_names}+${profile.last_name}&size=128`

    return (
        <div>
            <nav className="nav nav-pills flex-row fixed-bottom menubar">
                <MobileNavItem active={(active === 'dashboard') || !active} to="/app">
                    <i className="fas fa-home fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'titles'} to="/app/titles">
                    <i className="far fa-file-alt fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'fee-notes'} to="/app/fee-notes">
                    <i className="fas fa-file-invoice fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'accounts'} to="/app/accounts">
                    <i className="fas fa-university fa-fw"></i>
                </MobileNavItem>
                <a className="flex-fill text-center nav-link text-white" href="#." id="toggleMenu" onClick={handleClick}>
                    <i className="fas fa-bars fa-fw"></i>
                </a>
            </nav>

            <nav className={`vertical-nav ${additionalClass}`} id="sidebar">

                <div className="d-flex profile text-light align-items-center" style={{ backgroundColor: "rgba(255,255,255,0.2)" }}>
                    <img src={imgsrc} alt="..." width="65" className="rounded-circle img-thumbnail shadow-sm" />
                    <div className="ms-3 media-body">
                        <h4 className="m-0 font-weight-bold">{profile.display_name}</h4>
                        <p className="font-weight-light mb-0">{profile.job_title}</p>
                    </div>
                </div>


                <ul className="nav link-container d-flex flex-column mb-0">

                    <NavItem link="/app"
                        title="Dashboard"
                        active={['dashboard', ''].indexOf(active) !== -1}
                    >
                        <i className="fas fa-home fa-fw" />
                    </NavItem>

                    <div className="my-md-2"></div>

                    <NavItem link="/app/accounts"
                        title="Accounts"
                        active={active === 'accounts'}
                    >
                        <i className="fas fa-university fa-fw" />
                    </NavItem>

                    <NavItem link="/app/titles"
                        title="Titles"
                        active={active === 'titles'}
                    >
                        <i className="far fa-file-alt fa-fw" />
                    </NavItem>

                    <div className="my-1 my-md-3" />

                    <NavItem link="/app/fee-notes"
                        title="Fee Notes"
                        active={active === 'fee-notes'}
                    >
                        <i className="fas fa-file-invoice fa-fw" />
                    </NavItem>

                    <NavItem link="/app/expenses"
                        title="Expenses"
                        active={active === 'expenses'}
                    >
                        <i className="fas fa-coins fa-fw" />
                    </NavItem>

                    <div className="my-1 my-md-3" />

                    <NavItem link="/app/users"
                        title="Users"
                        active={active === 'users'}
                    >
                        <i className="fas fa-users fa-fw" />
                    </NavItem>

                    <div className="my-1 my-md-3" />



                    <NavItem link="/app/me"
                        title="My Profile"
                        active={active === 'me'}
                    >
                        <i className="fas fa-user-cog fa-fw" />
                    </NavItem>

                    <li className="nav-item">
                        <a href="#." onClick={handleLogout} className="nav-link" title="Log Out">
                            <i className="fas fa-sign-out-alt fa-fw" />
                            <span className="nav-text">Log Out</span>
                        </a>
                    </li>

                </ul>

                <div className="nav-toggler">
                    <a href="#." className="nav-link w-100 text-center px-0 text-light" id="sidenavToggler" onClick={handleExpand}>
                        <i className="fas fa-arrow-circle-left fa-fw fa-2x"></i>
                    </a>
                </div>
            </nav>
        </div>
    )
}

export default Menu;