import { Form, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { error as errorAlert, successAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import ContactInput from '../../components/contacts';
import { createAccount, updateAccount } from '../../resources/api/accounts';
import { getUsers } from '../../resources/api/users';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('./view-accounts').AccountObject} props.details
 * @param {(details: import('./view-accounts').AccountObject) => void} props.onSubmit
 */
const AccountForm = ({ details: accountObject, action, onSubmit }) => {


    const [details, setDetails] = useState(accountObject);
    const [rms, setRMS] = useState([]);
    const [users, setUsers] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        getUsers()
            .then(({ users }) => {
                users = users.map(r => ({ ...r, __id: r.id, id: r.contact_id }));
                setUsers(users);
            })
            .catch(err => errorAlert(`Could not contacts for RMs. ${err}`))
    }, [])


    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;



        if (action === "create") {

            promise =
                createAccount({
                    ...details,
                    feenoteAtEnd: details.feenoteAtEnd ? 1 : 0,
                    relationship_managers: rms
                });

        } else {

            promise = updateAccount(
                {
                    title: details.title,
                    description: details.description,
                    feenoteAtEnd: details.feenoteAtEnd ? 1 : 0
                },
                details.id
            )
        }

        promise.then(({ message, account }) => {
            successAlert(message);
            onSubmit(account);
        })
            .catch(err => {

                setSubmitting(false);
                errorAlert(err);

            })




    }

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Account Details</h4>
            <Row>
                <Col sm={12} className="my-1 g-2">
                    <Label className="form-field-title">Title</Label>
                    <Control
                        value={details.title}
                        minLength={8}
                        maxLength={60}
                        onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        The title should have a minimum of 8 and maximum of 60 characters.
                    </Feedback>
                </Col>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Description</Label>
                    <Control
                        as="textarea"
                        value={details.description}
                        minLength={8}
                        maxLength={500}
                        onChange={e => setDetails({ ...details, description: e.currentTarget.value })}
                        style={{ height: "100px" }}
                        onFocus={e => e.currentTarget.style.height = "300px"}
                        onBlur={e => e.currentTarget.style.height = "100px"}
                        required={true}
                    />
                    <Feedback type="invalid">
                        A few words to describe the account.
                    </Feedback>
                </Col>
                <Col sm={12} className='my-1'>
                    <Form.Check type="checkbox" id="newbatchcheck">
                        <Form.Check.Input
                            type="checkbox"
                            checked={details.feenoteAtEnd}
                            onChange={() => setDetails(d => ({ ...d, feenoteAtEnd: !d.feenoteAtEnd }))} />
                        <Form.Check.Label>Feenotes created at the end?</Form.Check.Label>
                    </Form.Check>
                </Col>
            </Row>


            {(action === "create") &&

                <>

                    <h4 className="form-section-label">Relationship Managers</h4>
                    <ContactInput
                        value={rms}
                        contacts={users}
                        placeholder="Choose one or more users from this list."
                        allowUpload={false}
                        onChange={val => setRMS(val.split(",").filter(r => !!r))}
                        maxContacts={5}

                    />
                </>

            }

            <Row>
                <Col className="mt-5 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Account" : "Create Account"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}



export default AccountForm;