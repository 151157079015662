import { APIURL, deleteData, getData, postData } from "../fetch";

/**
 * 
 * @typedef {{
 * display_name: string, 
 * other_names: string, 
 * last_name: string, 
 * email: string, 
 * telephone: string, 
 * id: string
 * __id: string
 * }} RMObject
 */

/**
 * 
 * @typedef {{
 * id: string,
 * title: string,
 * description: string, 
 * contact_persons: {name: string, email: string, telephone: string}[],
 * relationship_managers: RMObject[], 
 * no_titles: number, 
 * no_pending_titles: number, 
 * average_turnaround: number,
 * date_added: string,
 * added_by: string
 * }} AccountObject 
 */

/**
 * 
 * @param {AccountObject} data 
 * @returns {Promise<{code: number, message: string, account: AccountObject}>}
 */
const createAccount = (data) => {
    return postData({
        url: `${APIURL}/accounts`,
        data
    })
}

/**
 * 
 * @param {AccountObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, account: AccountObject}>}
 */
const updateAccount = (data, id) => {
    return postData({
        url: `${APIURL}/accounts/${id}`,
        data
    })
}

/**
 * 
 * @param {AccountObject} data 
 * @returns {Promise<{code: number, message: string, accounts: AccountObject[]}>}
 */
const getAccounts = () => getData({ url: `${APIURL}/accounts` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, account: AccountObject}>}
 */
const getAccount = (id, includes = null) => {

    const obj = { url: `${APIURL}/accounts/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}

/**
 * 
 * @param {string[]} rms Contact IDs of the relationship managers
 * @param {string} id
 * @returns {Promise<{code: number, message: string, relationship_managers: RMObject[]}>}
 */
const createRMs = (rms, id) => {
    return postData({
        url: `${APIURL}/accounts/${id}/relationship-managers`,
        data: { relationship_managers: rms }
    })
}

/**
 * 
 * @param {string} accountid Contact IDs of the relationship managers
 * @param {string} id RM ID
 * @returns {Promise<{code: number, message: string}>}
 */
const deleteRM = (accountid, id) => deleteData({
    url: `${APIURL}/accounts/${accountid}/relationship-managers/${id}`
})

/**
 * 
 * @param {string} accountid Contact IDs of the relationship managers
 * @param {string} id RM ID
 * @returns {Promise<{code: number, message: string, titles: import("../../app/titles/view-titles").TitleObject[]}>}
 */
const getTitlesAwaitingFeenotes = (account_id) => getData({ url: `${APIURL}/accounts/${account_id}/titles-for-feenotes` });


export {
    createAccount,
    getAccount,
    getAccounts,
    updateAccount,
    createRMs,
    deleteRM,
    getTitlesAwaitingFeenotes
}