import moment from "moment";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
import { Routes, Route, useParams, useNavigate, Link } from "react-router-dom";
import { firmRoles, titleStatusActivity } from "../../assets/constants";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { sortFunction, UCWords } from "../../components/resources";
import { Select } from "../../components/select";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import {
    deleteUser,
    getUser,
    resetUserPassword,
    suspendUser,
    updateRoles
} from "../../resources/api/users";
import { EditUser } from "./user-manipulation";


/**
 * 
 * @param {Object} props
 * @param {import("./view-users").UserObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import("./view-users").UserObject>} props.setDetails
 */
const ViewDetails = ({ details, setDetails }) => {

    const navigate = useNavigate();

    const nav_items = [
        { title: 'Users', href: "/app/users" },
        { title: details.display_name }
    ]

    const suspendAccount = () => {
        suspendUser(details.id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: "suspended" }));
            })
            .catch(e => errorAlert(e))
    }

    const deleteAccount = () => {
        deleteUser(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/");
            })
            .catch(e => errorAlert(e))
    }

    const resetAccountPassword = () => {
        resetUserPassword(details.id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: "reset" }));
            })
            .catch(e => errorAlert(e))
    }

    const updateUserRoles = (ids) => {
        if (ids.length === 0) return errorAlert("User should at least have a role.");

        infoAlert("Updating user roles...");

        updateRoles(ids, details.id)
            .then(({ user: { roles }, message }) => {
                setDetails(d => ({ ...d, roles }))
                successAlert(message);
            })
            .catch(e => errorAlert(e))
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.display_name}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Select
                            as={Btn}
                            title="Re-assign Roles"
                            items={firmRoles.map(i => ({ title: i, id: i }))}
                            maxItems={firmRoles.length}
                            onSelect={updateUserRoles}
                        >
                            <i className="fas fa-user-cog" />
                        </Select>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn title="Suspend Account" onClick={suspendAccount}>
                            <i className="far fa-times-circle" />
                        </Btn>
                        <Btn title="Delete Account" onClick={deleteAccount}>
                            <i className="far fa-trash-alt" />
                        </Btn>
                        <Btn title="Reset Password" onClick={resetAccountPassword}>
                            <i className="fas fa-user-lock" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/users/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/users/new" title="New User">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">

                <Row className="g-2">
                    <Col md={6}>
                        <Card className="my-2">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h4 className="my-2">Details</h4>
                                <span className="text-uppercase fw-bold">{details.account_status}</span>
                            </Card.Header>
                            <Card.Body>
                                <dl>
                                    <dt>Email</dt>
                                    <dd>
                                        {details.email}
                                    </dd>
                                    <dt>Telephone</dt>
                                    <dd>
                                        {details.telephone}
                                    </dd>
                                    <dt>Job Title</dt>
                                    <dd>
                                        {details.job_title}
                                    </dd>
                                    <dt>Roles</dt>
                                    <dd>
                                        {details.roles.length > 0 ?
                                            details.roles.map(e => UCWords(e.replace(/-/g, " "))).join(", ") :
                                            <span className="text-muted fs-italic">none assigned</span>
                                        }
                                    </dd>
                                    <dt>Accounts</dt>
                                    <dd>
                                        {(details.rm_accounts.length === 0) &&
                                            <span className="text-muted">No accounts</span>}
                                        {(details.rm_accounts.length > 0) &&
                                            details.rm_accounts.map(ra => (
                                                <>
                                                    <Link key={ra.id} to={`/app/accounts/${ra.id}`}>{ra.title}</Link> <br />
                                                </>
                                            ))}
                                    </dd>
                                    <dt>Last Logged In</dt>
                                    <dd>
                                        {details.last_logged_in ?
                                            moment(details.last_logged_in).format("HH:mm, DD MMMM YYYY")
                                            :
                                            'n/a'}
                                    </dd>
                                    <dt>Last Changed Password</dt>
                                    <dd>
                                        {moment(details.last_changed_password).format("HH:mm, DD MMMM YYYY")}
                                    </dd>
                                    <dt>Created</dt>
                                    <dd>
                                        {moment(details.date_added).format("HH:mm, DD MMMM YYYY")}
                                    </dd>
                                </dl>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="my-2">
                            <Card.Header>
                                <h4 className="my-2">Latest Activity</h4>
                            </Card.Header>
                            {(details.activity.length > 0) &&
                                <ListGroup variant="flush">
                                    {details.activity.slice(0, 8).map(a => (
                                        <ListGroup.Item key={a.id}>
                                            <div className="small d-flex justify-content-between text-muted">
                                                <Link to={`/app/titles/${a.title_id}`} >{a.title}</Link>
                                                <span>{a.display_name} | {moment(a.date_added).format("DD MMM YYYY, HH:mm")}</span>
                                            </div>
                                            <p className="mb-1">
                                                {titleStatusActivity[a.status]}
                                            </p>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>}

                            {(details.activity.length === 0) &&
                                <Card.Body>
                                    <p className="lead text-muted">
                                        No recent activity to display.
                                    </p>
                                </Card.Body>}
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )

}


const UserDetails = () => {

    const { userid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError(null);

        getUser(userid)
            .then(({ user }) => {
                user.activity.sort((a, b) => sortFunction(a, b, "date_added", "desc"));
                setDetails(user);
            })
            .catch(err => setError(err))
            .finally(() => setLoaded(true));

    }, [userid])

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/edit" element={<EditUser details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default UserDetails;