import { APIURL, getData, postData } from "../fetch";

/**
 * 
 * @typedef {{
 * id: string
 * feenote_id: string 
 * title: string
 * description: number 
 * amount: number 
 * date_added: string
 * added_by: string 
 * date_updated: string 
 * updated_by: string
 * client: string
 * }} NoteItem 
 */

/**
 * 
 * @typedef {{
 * id: string
 * feenote_id: string 
 * title_id: string
 * stamp_duty: number
 * extra_title: number
 * bank_charges: number
 * mortgage_reg:number
 * extra_deeds: number
 * upcountry: number
 * disbursements: number
 * prof_fee: number
 * vat: number 
 * deb: number
 * misc: number
 * total: number
 * date_added: string
 * added_by: string 
 * date_updated: string 
 * updated_by: string
 * client: string
 * }} ScheduleItem 
 */



/**
 * 
 * @typedef {{
 * id: string
 * manual_reference: string 
 * reference: string
 * note_no: number 
 * account_id: string 
 * feenote_date: string
 * instruction: string|null
 * loan_amount: number
 * total_amount: number 
 * paid_amount: number
 * balance: number
 * status: string 
 * isOld: boolean 
 * date_added: string
 * added_by: string 
 * date_updated: string 
 * updated_by: string
 * client: string
 * schedule_items: ScheduleItem[]
 * items: NoteItem[]
 * account: import("./accounts").AccountObject
 * payments: import("./payments").PaymentObject[]
 * expenses: import("./expenses").ExpenseObject[]
 * }} NoteObject 
 */

/**
 * 
 * @param {NoteObject} data 
 * @returns {Promise<{code: number, message: string, feenote: NoteObject}>}
 */
const createFeeNote = (data) => {
    return postData({
        url: `${APIURL}/fee-notes`,
        data
    })
}

/**
 * 
 * @param {NoteObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, feenote: NoteObject}>}
 */
const updateFeeNote = (data, id) => {
    return postData({
        url: `${APIURL}/fee-notes/${id}`,
        data
    })
}

/**
 * @param {?boolean} getAll
 * @param {{status: string|string[], account_id: string}} conditions
 * @returns {Promise<{code: number, message: string, feenotes: NoteObject[]}>}
 */
const getFeeNotes = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/fee-notes` };
    let data = {};

    if (getAll) data.expanded = 1;
    if (conditions) data = { ...data, ...conditions };
    if (Object.keys(data).length > 0) obj.data = data;

    return getData(obj);

}

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, feenote: NoteObject}>}
 */
const getFeeNote = (id, includes = null) => {

    const obj = { url: `${APIURL}/fee-notes/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}

/**
 * Advance status
 * @param {string} id 
 * @param {string} action 

 * @returns  {Promise<{code: number, message: string, note: {id: string, status: string}}>}
 */
const actionFeeNote = (id, action) => postData({ url: `${APIURL}/fee-notes/${id}/action/${action}` });


export {
    createFeeNote,
    getFeeNote,
    getFeeNotes,
    updateFeeNote,
    actionFeeNote
}