import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../../components/btns";
import { errorAlert, successAlert } from "../../../components/toastr";
import { createNote } from "../../../resources/api/titles";


/**
 * 
 * @param {Object} props
 * @param {string}  props.titleid
 * @param {(note: import("../../../resources/api/titles").NoteObject) => void} props.onCreate
 * @returns 
 */
const UploadComment = ({ onCreate, titleid }) => {

    const [text, setText] = useState("");
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);


    const handleClose = () => {
        setText("");
        setSubmitting(false);
    }

    const handleSubmit = e => {

        e.preventDefault();

        setSubmitting(true);

        createNote(titleid, text)
            .then(({ note, message }) => {
                setShow(false);
                successAlert(message);
                onCreate(note);
            })
            .catch(e => errorAlert(e))
            .finally(() => setSubmitting(false))
    }

    return (
        <>
            <Button
                variant="outline-secondary"
                size="sm"
                className="rounded-pill"
                onClick={() => setShow(true)}
            >
                <i className="fas fa-pen me-2" />
                Add Comment
            </Button>

            <Modal show={show} onExit={handleClose} centered>
                <Modal.Body>
                    <Form.Control as="textarea"
                        size="sm"
                        value={text}
                        placeholder="What would you like to say?"
                        onChange={e => setText(e.currentTarget.value)}
                        style={{ height: "200px" }}
                    />

                </Modal.Body>
                <Modal.Footer className="py-1">
                    {(text.length > 10) &&
                        <SubmitButton isSubmitting={submitting} onClick={handleSubmit}>
                            Add Note
                        </SubmitButton>
                    }

                    <CancelButton onClick={() => setShow(false)} isSubmitting={submitting}>
                        Cancel
                    </CancelButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UploadComment;