import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

/**
 * Shows a page Loading screen with a notch
 */
export const PageLoading = ({ children }) => {

    return (
        <div className="d-flex flex-column my-5 align-items-center text-muted p-3">
            <i className="fas fa-circle-notch fa-spin fa-5x"></i>
            <div className="mt-5 mb-3 lead"> {children || `Loading the page...`}</div>
        </div>
    )
}

/**
 * Displays an error
 * @param {{children: React.ReactNode}} param0 
 */
export const ErrorLoading = ({ children }) => {

    const navigate = useNavigate();

    return (
        <div className="max-600 my-5 text-center p-3 text-muted">
            <i className="fas fa-exclamation-triangle fa-5x text-danger"></i>
            <p className="lead my-4">
                Oops! Looks like something went wrong! <br /> {children}
            </p>
            <Button variant="outline-danger" className="rounded-pill" onClick={() => navigate(-1)}>
                <i className="fas fa-hand-point-left me-2"></i>Go Back
            </Button>
        </div>

    )
}