
import { Form } from 'react-bootstrap';
import { SubmitButton } from '../components/btns';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { loginUser, forgotPassword } from '../resources/api/auth';
import { errorAlert, infoAlert, successAlert } from '../components/toastr';
import { validateEmail } from '../components/resources';
import { AppUserContext } from '../App';


const { Control, Label } = Form;

/**
 * If a user is not logged in then the user is prompted to log in. 
 * @param {{
 * 
 * }} param0 
 */
const Login = ({ loginUser: registerLogIn }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [validated, setValidated] = useState(false);

    const { isLoggedIn } = useContext(AppUserContext);
    const { search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) navigate("/app");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            errorAlert("Some of your input is incorrect. These errors have been highlighted for you.");
            setValidated(true);
            return;
        }

        setValidated(false);
        setSubmitting(true);

        infoAlert("Logging you in ....");

        loginUser(email, password)
            .then(({ user_details }) => {
                registerLogIn(user_details);
                const nextPage = (new URLSearchParams(search)).get("redirect") || "/app";
                navigate(nextPage);
            })
            .catch(err => {
                setSubmitting(false);
                errorAlert(err);
            })
    }

    const handleRecover = e => {
        e.preventDefault();

        if (!email) {
            errorAlert("Provide your email address to recover your account.");
            return;
        }

        if (!validateEmail(email)) {
            errorAlert("The email address provided seems to be invalid. Provide a valid email address.");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        infoAlert("Resetting your account password...");

        forgotPassword(email)
            .then(({ message }) => {
                successAlert(message);
            })
            .catch(err => {
                errorAlert(err);

            })
            .finally(() => setSubmitting(false))
    }

    return (
        <>

            <div style={{ height: "100vh" }} className="d-flex p-3 mx-auto flex-column justify-content-center align-items-center">

                <img src="/logo/logo-top.png" id="logo" alt="Company Logo" style={{ width: "300px" }} />

                <div className="login-container mt-4 mt-sm-5" style={{ width: '20rem', maxWidth: "90%" }}>

                    {/* <h3 className="mb-4 mb-sm-5 text-center font-weight-bold">Login</h3>s */}

                    <Form noValidate onSubmit={handleSubmit} validated={validated}>

                        <div className="my-2">
                            <Label className="form-field-title">Email Address</Label>
                            <Control
                                // placeholder="john@doe.com"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.currentTarget.value)}
                                required

                            />
                            <Control.Feedback type="invalid">
                                Provide a valid email address.
                            </Control.Feedback>
                        </div>
                        <div className="my-2">
                            <Label className="form-field-title">Password</Label>
                            <Control
                                // placeholder="your account password"
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.currentTarget.value)}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Password is required.
                            </Control.Feedback>
                        </div>

                        <div className="mt-4">
                            <SubmitButton
                                className="w-100 rounded-pill"
                                isSubmitting={submitting}
                                type="submit"
                            >
                                Login
                            </SubmitButton>
                        </div>

                    </Form>

                    <div className="text-end small mt-3">
                        <a onClick={handleRecover} href="#." className="text-muted">Forgot Password?</a>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Login;