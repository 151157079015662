import { useRef, useState } from "react";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../../components/btns";
import { errorAlert, successAlert } from "../../../components/toastr";
import { titleDocumentCategories } from "../../../assets/constants";
import { UCWords } from "../../../components/resources";
import FileInput from "../../../components/files";
import { uploadDocument } from "../../../resources/api/titles";

const { Label, Control, Group, Select } = Form;

/**
 * 
 * @param {Object} props
 * @param {string} props.title_id
 * @param {(document: import("../view-titles").TitleDocObject) => void} props.onUpload
 */
const UploadDocument = ({ onUpload, title_id }) => {

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [handlingSubmit, setHandlingSubmit] = useState(false);

    const [file, setFile] = useState("");
    const [category, setCategory] = useState('');

    const ref = useRef();

    /**
     * 
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = ref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have some errors in your form.");
            return;
        }

        setValidated(false);
        setHandlingSubmit(true);

        uploadDocument(title_id, { file_id: file, category })
            .then(({ message, document }) => {
                successAlert(message);
                onUpload(document);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => {
                form.reset();
                setHandlingSubmit(false);
            })

    }

    return (

        <>
            <Button
                variant="outline-secondary"
                size="sm"
                className="rounded-pill"
                onClick={() => setShow(true)}
            >
                <i className="fas fa-file-upload me-2" />
                Upload Document
            </Button>

            <Modal show={show} onExit={() => setValidated(false)} centered>
                <Modal.Body>
                    <Form noValidate validated={validated} ref={ref}>

                        <Row>
                            <Col sm={12} className="my-1">
                                <Group controlId="category">
                                    <Label className="form-field-title">Category</Label>
                                    <Select
                                        value={category}
                                        onChange={e => setCategory(e.currentTarget.value)}
                                        required
                                    >
                                        <option value="">Select an option</option>
                                        {titleDocumentCategories.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                                    </Select>
                                    <Control.Feedback type="invalid">
                                        Category is required.
                                    </Control.Feedback>
                                </Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="my-1">
                                <Label className="form-field-title">File</Label>
                                <FileInput
                                    value={[file]}
                                    maxFiles={1}
                                    onChange={([id]) => setFile(id || "")}
                                    required
                                    errorText="You must upload a file"
                                    placeholder="Select a file you want to upload"
                                />
                            </Col>
                        </Row>



                        <Row className="mt-3 text-end">
                            <Col>
                                <SubmitButton
                                    onClick={handleSubmit}
                                    isSubmitting={handlingSubmit}
                                >
                                    Upload File
                                </SubmitButton>
                                <CancelButton
                                    isSubmitting={handlingSubmit}
                                    onClick={() => setShow(false)}
                                >
                                    Cancel
                                </CancelButton>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default UploadDocument;