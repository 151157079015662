import { Card } from "react-bootstrap"




/**
 * 
 * @param {Object} props
 * @param {"secondary"|"primary"|"danger"|"warning"|"info"|"success"} props.bg
 * @param {"dark"|"light" props.text} 
 * @param {string} props.figure
 * @param {string} props.description
 * @param {string} props.icon
 * @returns 
 */
const StatusCard = ({
    bg = "primary",
    text = "light",
    figure,
    description,
    icon
}) => {

    const style = { right: "15px", top: "calc(50% - 2rem)", fontSize: "4rem", color: "rgba(255,255,255,0.6)" }

    return (
        <Card bg={bg} text={text} className="position-relative shadow">
            <Card.Body>
                <div className='display-5 mb-2'>
                    {figure}
                </div>
                <span className="fw-bold">{description}</span>
            </Card.Body>
            <i
                className={`position-absolute ${icon}`}
                style={style}
            />
        </Card>
    )
}

export { StatusCard };