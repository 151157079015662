import moment from "moment";
import React, { useRef, useState } from "react";
import { Col, Row, Form, Modal } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../../components/btns";
import cur from "../../../components/currency";
import { DateSelector } from "../../../components/datetimepicker";
import { errorAlert, successAlert } from "../../../components/toastr";
import { createPayment } from "../../../resources/api/payments";

const { Label, Control, Group } = Form;


/**
 * 
 * @param {Object} props
 * @param {boolean} props.show
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setShow
 * @param {number} props.maxAmount
 * @param {string} props.noteid
 * @param {(note: import("../../../resources/api/fee-notes").NoteObject) => void} props.onAdd
 * @returns 
 */
const AddPayment = ({ show, setShow, onAdd, noteid, maxAmount }) => {

    // const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [handlingSubmit, setHandlingSubmit] = useState(false);


    const [amount, setAmount] = useState("");
    const [payment_date, setPaymentDate] = useState('');

    const ref = useRef();



    /**
     * 
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const f = ref.current;

        if (!f.checkValidity()) {
            errorAlert("Oops! There are some errors in your form. These have been highlighted for you.");
            setValidated(true);
            setHandlingSubmit(false);
            return;
        }

        setValidated(false);
        setHandlingSubmit(true);

        createPayment({
            amount,
            payment_date,
            feenote_id: noteid
        })
            .then(({ message, payment }) => {
                successAlert(message);
                onAdd(payment);
                setAmount("");
                setPaymentDate("");
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (



        <Modal show={show} onExit={() => setValidated(false)} centered size="sm">
            <Modal.Header className="bg-danger text-white">
                <h5 className="mb-0">Add New Payment</h5>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} ref={ref}>

                    <Row className="g-1">
                        <Col sm={12} className="my-1">
                            <Group controlId="names">
                                <Label className="form-field-title">Paid Amount</Label>
                                <Control
                                    type="number"
                                    min={0}
                                    step="0.05"
                                    value={amount}
                                    onChange={e => setAmount(e.currentTarget.value)}
                                    max={maxAmount}
                                    required
                                />
                                <Control.Feedback type="invalid">
                                    The amount paid must be provided and cannot be more than {cur(maxAmount).format()}.
                                </Control.Feedback>
                            </Group>
                        </Col>

                        <Col sm={12} className="my-1">
                            <Group controlId="names">
                                <Label className="form-field-title">Date of Payment</Label>
                                <DateSelector
                                    value={payment_date}
                                    onChange={val => setPaymentDate(val)}
                                    minDate={moment().subtract(7, "d").format("YYYY-MM-DD")}
                                    maxDate={0}
                                    required
                                />
                                <Control.Feedback type="invalid">
                                    You must provide the date when the payment was made.
                                </Control.Feedback>
                            </Group>
                        </Col>

                    </Row>


                    <Row className="mt-3 text-end">
                        <Col>
                            <SubmitButton
                                onClick={handleSubmit}
                                isSubmitting={handlingSubmit}
                            >
                                Add Payment
                            </SubmitButton>
                            <CancelButton
                                isSubmitting={handlingSubmit}
                                onClick={() => setShow(false)}
                            >
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>


            </Modal.Body>
        </Modal>
    )
}

export default AddPayment;