import { Routes, Route } from "react-router-dom"
import TitleDetails from "./title-details";
import { NewTitle } from "./title-maniplulation";
import ViewTitles from "./view-titles";

const Titles = () => {

    return (
        <Routes>
            <Route path="/new" element={<NewTitle />} />
            <Route path="/:titleid/*" element={<TitleDetails />} />
            <Route path="/" element={<ViewTitles />} />
        </Routes>
    )
}

export default Titles;