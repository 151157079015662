import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useState, useEffect, useMemo } from 'react';
import { DateSelector } from './../../components/datetimepicker';
import { errorAlert, infoAlert, successAlert } from './../../components/toastr';
import moment from 'moment';
import { SubmitButton, CancelButton } from './../../components/btns';
import { ExpenseItems } from './../../components/finances';
import currency from './../../components/currency';
import File from './../../components/files';
import { expenseCategories, paymentMethods } from '../../assets/constants';
import { UCWords } from '../../components/resources';
import SelectInput from '../../components/select';
import { getAccounts } from '../../resources/api/accounts';
import { getFeeNotes } from '../../resources/api/fee-notes';
import cur from './../../components/currency';
import { createExpense, updateExpense } from '../../resources/api/expenses';


const { Control, Control: { Feedback }, Label, Text } = Form;


/**
 * 
 * @param {Object} props
 * @param {"create"|"edit"} props.action 
 * @param {import('../../resources/api/expenses').ExpenseObject} props.details 
 * @param {(details: import('../../resources/api/expenses').ExpenseObject) => void} props.onSubmit 
 */
const ExpenseForm = ({ action = "create", details: expenseObject, onSubmit }) => {

    const [details, setDetails] = useState(expenseObject);
    const [items, setItems] = useState(expenseObject.items || []);
    const [deleted_items, setDeletedItems] = useState([]);

    const [files, setFiles] = useState([]);
    const [feenotes, setFeeNotes] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const [accounts, setAccounts] = useState([]);


    const tax = useMemo(() => {

        const _tax = parseFloat(details.tax),
            _amount = parseFloat(details.amount);

        if (_tax === 0 || !_tax || _amount === 0 || !_amount) return 0;


        if (details.isTaxInclusive) {
            return _amount - (_amount / (1 + _tax / 100));
        } else {
            return _tax / 100 * _amount;
        }
    }, [details.isTaxInclusive, details.amount, details.tax]);


    useEffect(() => {
        getAccounts()
            .then(({ accounts }) => setAccounts(accounts))
            .catch(e => errorAlert(`Unable to load accounts. Error: ${e}`))
    }, [])


    /**
     * when the account changes, get already sent fee notes.
     */
    useEffect(() => {

        if (!details.account_id) return setFeeNotes([]);

        infoAlert("Loading fee-notes for this account...", "Please Hold");

        getFeeNotes(false, { account_id: details.account_id })
            .then(({ feenotes }) => {
                setFeeNotes(
                    feenotes.map(f => ({
                        ...f,
                        title: `${f.note_no} | ${f.reference}`,
                        description: `${moment(f.feenote_date).format("DD MMM YYYY")} - ${cur(f.total_amount).format()}`
                    })));
                successAlert("Loaded the feenotes for this account.");
            })
            .catch(e => errorAlert(e))


    }, [details.account_id])




    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const data = {
            ...details,
            isItemized: details.isItemized ? 1 : 0,
            isBillable: details.isBillable ? 1 : 0,
            isTaxInclusive: details.isTaxInclusive ? 1 : 0,
            tax_percent: details.tax_percent || 0
        }


        let promise;

        if (action === "create") {
            data.items = items;
            promise = createExpense(data);
        } else {
            promise = updateExpense({
                ...data,
                new_items: items.filter(i => i.isNew),
                updated_items: items.filter(i => (!!i.id && i.hasChanged)),
                deleted_items
            }, data.id)
        }

        promise
            .then(({ expense, message }) => {
                successAlert(message);
                onSubmit(expense);
            })
            .catch(e => {
                setSubmitting(false);
                errorAlert(e);
            })

    }

    return (

        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label mt-5">Expense Details</h4>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Expense Category</Label>
                    <Form.Select
                        as="select"
                        value={details.category}
                        onChange={e => setDetails({ ...details, category: e.currentTarget.value })}
                        required
                    >
                        <option value="">Select an expense category</option>
                        {expenseCategories.map(c => <option key={c} value={c}>{UCWords(c).replace(/-/g, " ")}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select a category for this expense.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Expense Title</Label>
                    <Control placeholder="Serial Book Purchases"
                        maxLength={60}
                        value={details.title}
                        onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                    />
                    <Text muted>
                        Something to remember this expense by. Should be less than 60 characters. (Optional)
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Expense Date</Label>
                    <DateSelector
                        minDate={moment().add(-1, 'months').format('YYYY-MM-DD')}
                        maxDate={0}
                        placeholder="Select a date"
                        value={details.expense_date}
                        onChange={val => setDetails(d => ({ ...d, expense_date: val }))}
                        required
                    />
                    <Feedback type="invalid">
                        You must select an expense date.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Method</Label>
                    <Form.Select
                        as="select"
                        value={details.payment_method}
                        onChange={e => setDetails({ ...details, payment_method: e.currentTarget.value })}
                        required
                    >
                        <option value="">Select a payment method</option>
                        {paymentMethods.map(c => <option key={c} value={c}>{UCWords(c).replace(/-/g, " ")}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select a method for payment for this expense.
                    </Feedback>
                </Col>
            </Row>
            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Description/Notes</Label>
                    <Control placeholder="You might want to throw more clarity on the expense."
                        as="textarea"
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        value={details.description || ""}
                        onChange={e => setDetails({ ...details, description: e.currentTarget.value })}
                    />
                    <Feedback type="invalid">
                        If the payment method is not cash, fees should be provided. Put 0 is none.
                    </Feedback>
                </Col>
            </Row>

            {!details.isItemized &&
                <Row>
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Total Amount</Label>
                        <InputGroup>
                            <Control placeholder="Total amount spent"
                                type="number"
                                step={0.01}
                                value={details.amount}
                                onChange={e => setDetails({ ...details, amount: e.currentTarget.value })}
                                required
                            />
                            <Button
                                variant="secondary"
                                className="border-0"
                                title="Itemize Expense"
                                onClick={() => setDetails(d => ({ ...d, isItemized: true }))}
                            >
                                <i className="fas fa-sitemap" />
                            </Button>
                            <Feedback type="invalid">
                                You must provide an amount
                            </Feedback>
                        </InputGroup>
                        <Text muted>
                            You can decide to itemize the expense by clicking the <i className="fas fa-sitemap" /> button.
                            This means that you put in the individual items and the system will calculate the total amount.
                        </Text>
                    </Col>
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Tax</Label>
                        <Form.Select
                            placeholder="Total amount spent"
                            value={details.tax_percent}
                            onChange={e => setDetails({ ...details, tax_percent: e.currentTarget.value })}

                        >
                            <option value="">None</option>
                            <option value="18">VAT 18%</option>
                        </Form.Select>
                        {tax > 0 &&
                            <Text muted>Total tax of UGX {currency(tax).format()}</Text>}
                    </Col>
                </Row>
            }

            <Row>
                <Col className="my-1">
                    <Label className="form-field-title">Are prices tax inclusive?</Label>
                    <Form.Check
                        className=""
                        type="switch"
                        id="custom-switch"
                        checked={details.isTaxInclusive}
                        onChange={() => setDetails(d => ({ ...d, isTaxInclusive: !d.isTaxInclusive }))}
                        label={details.isTaxInclusive ? "Yes, prices are tax inclusive." : "No, prices are not tax inclusive."}
                    />
                </Col>
            </Row>

            {details.isItemized &&
                <>
                    <h4 className="form-section-label mt-5">Expense Items</h4>

                    <ExpenseItems
                        expenseitems={{ items, setItems }}
                        deleteditems={{ deleted_items, setDeletedItems }}
                        isTaxInclusive={details.isTaxInclusive}
                    >
                        <Button
                            variant="secondary"
                            size="sm"
                            className="rounded-pill m-1"
                            onClick={() => setDetails(d => ({ ...d, isItemized: false }))}
                        >
                            <i className="fas fa-ban me-2" />Unitemize Items
                        </Button>
                    </ExpenseItems>
                </>
            }


            <h4 className="form-section-label mt-5">Other Details</h4>
            <Text muted>
                Add the expense to an account. If the expense is billable, then it must be added to an account and a fee note must not be attached to it. Optionally you can add a feenote to track expenses against a feenote.
            </Text>



            <Row>
                <Col sm={12} className="my-2">
                    <Label className="form-field-title">Is Billable?</Label>
                    <Form.Check
                        className=""
                        type="switch"
                        id="is-billable"
                        checked={details.isBillable}
                        onChange={() => setDetails(d => ({ ...d, isBillable: !d.isBillable }))}
                        label={details.isBillable ? "Yes, expense is billable." : "No, expense is not billable."}
                    />
                </Col>
                <Col sm={6} className="my-2">
                    <Label className="form-field-title">Account</Label>
                    <SelectInput
                        value={[details.account_id || ""]}
                        onChange={([id]) => setDetails(d => ({ ...d, account_id: id || "", feenote_id: "" }))}
                        options={accounts}
                        maxItems={1}
                        placeholder="Select an account"
                        required={details.isBillable}
                        errorText="An account must be selected if this expense is billable."
                    />
                </Col>
                <Col sm={6} className="my-2">
                    <Label className="form-field-title">Fee Note</Label>
                    <SelectInput
                        value={[details.feenote_id || ""]}
                        onChange={([id]) => setDetails(d => ({ ...d, feenote_id: id || "" }))}
                        options={feenotes}
                        maxItems={1}
                        placeholder={!details.account_id ? "Choose an account first" :
                            (feenotes.length === 0 ? "Account has no paid feenotes" : "Choose a feenote")}
                        required={!details.isBillable && !!details.account_id}
                        errorText="If this expense is not billable but has an account, you must choose a fee note."
                    />
                </Col>
            </Row>




            <Row>
                <Col sm={6} className="my-2">
                    <Label className="form-field-title">Receipt</Label>
                    <File maxFiles={1} onChange={files => setFiles(files)} value={files} />
                </Col>
            </Row>

            {/* <h4 className="form-section-label">Expense Notes</h4>

            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Notes</Label>
                    <Control placeholder="Anything"
                        as="textarea"
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        value={details.notes}
                        onChange={e => setDetails({ ...details, notes: e.currentTarget.value })}
                    />
                </Col>
            </Row> */}

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        Submit Expense
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>

        </Form>
    )
}


export default ExpenseForm;