import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { errorAlert, infoAlert, successAlert } from '../../../components/toastr';
import moment from 'moment';
import { DateSelector } from '../../../components/datetimepicker';
import { advanceTitleStatus, closeTitle } from "../../../resources/api/titles";
import { sortFunction, UCWords } from '../../../components/resources';



/**
 * 
 * @typedef {{id: string, title: string, description: ?string, [any: string]: any}} ItemObject
 */

/**
 * @param {Object} props
 * @param {{show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>}} props.toShow
 * @param {{titles: import('../view-titles').TitleObject[]}} props.titles
 * @param {string} props.status
 * @param {"advance"|"close"|"cancel"} props.action
 * @param {?string} props.reason
 */
const ChooseOthers = ({ toShow, status, titles, title_id, setTitleDetails, action = "advance", reason = null }) => {


    const { show, setShow } = toShow;
    const [selected, setSelected] = useState([]);
    const [activity_date, setActivityDate] = useState(moment().format("YYYY-MM-DD"));

    /**
     * when hiding the modal, set selected to zero 
     * so that when someone comes back they can come back to no previously selected values
     */
    useEffect(() => {
        if (!show) setSelected([]);
    }, [show])


    /**
     * A a file to the list of seelcted files.
     * @param {string} id - ID of the file to add
     */
    const handleClick = id => {

        if (selected.indexOf(id) === -1) {
            setSelected([...selected, id]);
        } else {
            setSelected([...selected.filter(i => i !== id)]);
        }

    }

    const handleSubmit = () => {


        infoAlert("Please hold...");

        if (action === "advance") {

            advanceTitleStatus(title_id, { activity_date, batch_titles: selected })
                .then(({ title, message }) => {
                    title.activity.sort((a, b) => sortFunction(a, b, 'date_added', 'asc'));
                    setTitleDetails(d => ({ ...d, ...title }));
                    successAlert(message);
                    setShow(false);
                })
                .catch(e => errorAlert(e));

        } else {

            let _status = action === "close" ? "returned" : "unresolved";

            closeTitle(title_id, _status, { reason, activity_date, batch_titles: selected })
                .then(({ message, title }) => {
                    setTitleDetails(d => ({
                        ...d,
                        ...title
                    }));
                    successAlert(message);
                    setShow(false);
                })
                .catch(e => errorAlert(e));
        }



    }




    return (
        <Modal show={show} centered scrollable backdrop="static">
            <Modal.Header className="pt-3 justify-content-between align-items-sm-center border-bottom-0 flex-column flex-sm-row bg-secondary text-white">
                <h4 className='my-0'>{UCWords(action)} Title</h4>
            </Modal.Header>
            <Modal.Body className="py-1">

                {(status === "stampduty-paid" || status === "repossessed") &&
                    <div className="my-2 my-sm-3">
                        <Form.Label className='form-field-title'>Activity Date</Form.Label>
                        <DateSelector
                            value={activity_date}
                            onChange={val => setActivityDate(val || "")}
                            required={true}
                            minDate={moment().subtract(5, "d").format("YYYY-MM-DD")}
                            maxDate={0}
                        />
                    </div>
                }


                {(titles.filter(t => t.status === status).length > 0) &&
                    <div className='mt-4'>
                        <Form.Label className='form-field-title'>Tiles to action</Form.Label>
                        <Form.Text>Choose the titles to which this action should apply</Form.Text>
                        <ListGroup variant="flush">
                            {titles
                                .filter(t => t.status === status)
                                .map(f => (
                                    <ListGroup.Item key={f.id}
                                        action
                                        onClick={() => handleClick(f.id)}
                                        active={selected.indexOf(f.id) !== -1}
                                    >
                                        <h5 className="mb-0 font-weight-normal">{f.title}</h5>
                                        <div className="small text-muted mt-1" style={{ lineHeight: "0.95rem" }}>
                                            Customer: {f.customer} | Date: {moment(f.date_added).format("DD MMM YYYY")}
                                        </div>
                                    </ListGroup.Item>
                                ))}
                        </ListGroup>
                    </div>
                }


            </Modal.Body>
            <Modal.Footer>
                <span className="d-inline-block small me-auto text-muted">
                    {titles.length} items
                </span>
                <Button variant="success" onClick={handleSubmit} className="px-2 px-sm-3 m-1 rounded-pill">
                    <i className="fas fa-thumbs-up me-1 me-sm-2" />Submit
                </Button>
                <Button variant="secondary" className="rounded-pill px-2 px-sm-3 m-1" onClick={() => setShow(false)} >
                    <i className="fas fa-times-circle me-1 me-sm-2"></i>Cancel
                </Button>
            </Modal.Footer>
        </Modal>

    )
}


export default ChooseOthers;
