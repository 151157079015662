import { APIURL, getData, postData } from "../fetch";


/**
 * 
 * @typedef {{
 *  id: string 
 *  expense_id: string
 *  unit_price: number
 *  quantity: number
 *  title: string
 *  tax_percent: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 * }} ExpensetItemObject
 */

/**
 * 
 * @typedef {{
 *  id: string 
 *  reference: string
 *  expense_no: string 
 *  expense_date: string
 *  feenote_id: string 
 *  account_id: string
 *  category: string 
 *  title: string
 *  description: string
 *  amount: number 
 *  tax_percent: number 
 *  pretax_amount: number 
 *  tax_amount: number 
 *  total_amount: number 
 *  isTaxInclusive: boolean 
 *  isBillable: boolean
 *  isItemized: boolean 
 *  receipt_file_id: string
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  account: import("./accounts").AccountObject|string
 *  feenote: import("./fee-notes").NoteObject|string
 *  items: ExpensetItemObject[]
 * }} ExpenseObject
 */


/**
 * 
 * @param {ExpenseObject} data 
 * @returns {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const createExpense = data => {
    return postData({
        url: `${APIURL}/expenses`,
        data
    })
}

/**
 * 
 * @param {ExpenseObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const updateExpense = (data, id) => {
    return postData({
        url: `${APIURL}/expenses/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, expenses: ExpenseObject[]}>}
 */
const getExpenses = (getAll = false) => {

    const obj = { url: `${APIURL}/expenses` }

    if (getAll) obj.data = { expanded: 1 }

    return getData(obj)

}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const getExpense = (id, includes = null) => {

    const obj = { url: `${APIURL}/expenses/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteExpense = id => getData({ url: `${APIURL}/expenses/${id}`, method: "DELETE" })


export {
    createExpense,
    getExpense,
    getExpenses,
    updateExpense,
    deleteExpense
}