import { Form, Button, Modal, Row, Col, ListGroup, InputGroup as Group } from 'react-bootstrap';
import { useState, useEffect, useRef, useContext } from 'react';
import { sortFunction } from './resources';
import { APIURL } from '../resources/fetch';
import { error as errorAlert, success as successAlert } from './toastr';
import { getFiles } from '../resources/api/users';
import { AppUserContext } from '../App';
import moment from 'moment';

const itemStyle = {
    whiteSpace: 'nowrap',
    padding: '.05rem .6rem',
    margin: '.25rem .25rem',
    display: 'inline-block',
    backgroundColor: 'var(--bs-secondary)',
    fontSize: '.8rem',
    borderRadius: '2rem'
}

/**
 * 
 * @param {{
 * onUpload: ({id: string, title: string, description: string, slug: string}) => void
 * accept: string 
 * capture: string 
 * variant: string
 * size: string 
 * imageID: string 
 * fileCategory: string
 * }} props 
 */
const FileUpload = ({ onUpload, accept, capture, variant, size, fileCategory, imageID, children, className = "" }) => {

    const { Group, Control, Label, Text } = Form;

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [handlingSubmit, setHandlingSubmit] = useState(false);

    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);

    const ref = useRef(null);


    const acceptable_accepts = ['image', 'audio', 'video'];

    const inputAccept = (acceptable_accepts.indexOf(accept) !== -1) ? `${accept}/*` : '*',
        inputCapture = ((inputAccept !== '*') && capture),
        handleFileChange = e => {
            let n = e.currentTarget.files[0].name;
            n = n.split(".");
            n = n.slice(0, n.length - 1);

            setFile(e.currentTarget.files[0]);
            setTitle(n.join("."));
        };


    /**
     * 
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const f = ref.current;

        if (!f.checkValidity()) {
            errorAlert("Oops! There are some errors in your form. These have been highlighted for you.");
            setValidated(true);
            setHandlingSubmit(false);
            return;
        }

        setValidated(false);
        setHandlingSubmit(true);


        const url = `${APIURL}/files`,
            formdata = new FormData(),
            xhr = new XMLHttpRequest();

        formdata.append('file', file);
        formdata.append('title', title);

        xhr.onreadystatechange = () => {

            if (xhr.readyState !== 4) return;
            if (xhr.status === 200 || xhr.status === 201) {

                setHandlingSubmit(false);

                try {
                    const { code, message, file } = JSON.parse(xhr.responseText);

                    if (code !== 0) return errorAlert(message);

                    setShow(false);
                    onUpload(file);
                    return successAlert(`File successfully uploaded. ${message}`);

                } catch (e) {
                    return errorAlert('Sorry, an unknown error occurred. Error: ' + e.message);
                }
            }

            try {
                const r = JSON.parse(xhr.responseText);
                return errorAlert(r.message);

            } catch (e) {
                return errorAlert(xhr.responseText);
            }

        }

        xhr.withCredentials = true;
        xhr.open('POST', url, true);
        xhr.send(formdata);

    }

    return (
        <>
            <Button variant={variant || "secondary"}
                className={className} size={size || ""}
                onClick={() => setShow(true)}
            >{children}</Button>

            <Modal show={show} onExit={() => setValidated(false)} centered animation={false}>
                <Modal.Body>
                    <Form noValidate validated={validated} ref={ref}>


                        <Row>
                            <Col className="my-1">
                                <Label className="form-field-title">File</Label>
                                <Control type="file"
                                    placeholder="Upload the file"
                                    onChange={handleFileChange}
                                    required
                                    capture={inputCapture}
                                    accept={inputAccept}
                                />
                                <Control.Feedback type="invalid">
                                    A file must be uploaded.
                                </Control.Feedback>
                                {inputAccept !== '*' &&
                                    <Text muted>
                                        Only files of type {accept} are permitted for upload.
                                    </Text>
                                }
                            </Col>
                        </Row>


                        <Row>
                            <Col sm={12} className="my-1">
                                <Group controlId="names">
                                    <Label className="form-field-title">Title</Label>
                                    <Control
                                        placeholder="Title of the file"
                                        minLength="5"
                                        maxLength="30"
                                        pattern="[A-Za-z0-9'@,.£$*() ]+"
                                        value={title} onChange={e => setTitle(e.currentTarget.value)} required
                                    />
                                    <Control.Feedback type="invalid">
                                        Give the file a name between 5 and 30 characters.
                                    </Control.Feedback>
                                    <Text muted>
                                        Should be between 5 and 30 characters and cannot contain any special characters expect '@,.£$*(). No characters {title.length}
                                    </Text>
                                </Group>
                            </Col>
                        </Row>



                        <Row className="mt-3 text-end">
                            <Col>
                                <Button
                                    variant="success"
                                    className="px-3 m-1 rounded-pill"
                                    onClick={handleSubmit}
                                    disabled={handlingSubmit}
                                >
                                    {handlingSubmit ?
                                        <i className="fas fa-circle-notch fa-spin me-2" /> :
                                        <i className="fas fa-thumbs-up me-2" />}
                                    Upload File
                                </Button>
                                <Button
                                    variant="secondary"
                                    className="rounded-pill px-3 m-1"
                                    onClick={() => setShow(false)}
                                    disabled={handlingSubmit}
                                >
                                    <i className="fas fa-times-circle me-2" />
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}


/**
 * 
 * @param {{
 * variant: string
 * size: string
 * className: string
 * maxFiles: number | false
 * onChooseFiles: (fileids: string[]) => void
 * }} param0 
 */
const FileSelector = ({ variant, size, className = "", children, maxFiles = 1, files, onChooseFiles }) => {

    const [show, setShow] = useState(false);
    const { Control } = Form;

    const [filteredFiles, setFilteredFiles] = useState([]);
    const [displayFiles, setDisplayFiles] = useState([]);

    const [selected, setSelected] = useState([]);

    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('');

    /**
     * 1. addFiles on finished
     */


    /**
     * on chnage of filter and search value
     */
    useEffect(() => {
        if (!filter) return setFilteredFiles(files);
        return setFilteredFiles([...files.filter(i => i.file_group.toLowerCase() === filter.toLowerCase())]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, JSON.stringify(files)]);

    /**
     * on chnage of filter and search value
     */
    useEffect(() => {
        if (search.length < 2) return setDisplayFiles(filteredFiles);

        setDisplayFiles([...filteredFiles.filter(i => i.title.toLowerCase().indexOf(search.toLowerCase()) !== -1)]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, JSON.stringify(filteredFiles)]);

    useEffect(() => {
        const srtFn = (a, b) => sortFunction(a, b, 'title');
        setFilteredFiles(files.sort(srtFn));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files.length])

    useEffect(() => {
        if (!show) setSelected([]);
    }, [show])


    const icons = {
        images: "far fa-file-image",
        pdfs: "far fa-file-pdf",
        sheets: "far fa-file-excel",
        docs: "far fa-file-word",
        slides: "far fa-file-powerpoint",
        videos: "far fa-file-video",
        others: "far fa-file-alt"

    }

    /**
     * A a file to the list of seelcted files.
     * @param {string} id - ID of the file to add
     */
    const handleClick = id => {

        if (selected.indexOf(id) === -1) {

            if (selected.length >= (maxFiles || selected.length + 1))
                return errorAlert("You have selected the maximum number of files you are permitted to choose.");

            setSelected([...selected, id]);
        } else {
            setSelected([...selected.filter(i => i !== id)]);
        }

    }

    const submitFiles = () => {
        onChooseFiles(selected);
        setShow(false);
        setSelected([]);
    }

    /**
     * Remove file id from list of selected items
     * @param {React.MouseEvent} e 
     * @param {string} id 
     */
    const removeFile = (e, id) => {
        e.preventDefault();
        setSelected(s => ([...s.filter(i => i !== id)]))
    }

    return (
        <>
            <Button variant={variant || "secondary"} className={className} size={size || ""} onClick={() => setShow(true)}>
                {children}
            </Button>

            <Modal size="lg" show={show} centered animation={false} scrollable backdrop="static">
                <Modal.Header className="pt-3 justify-content-between border-bottom-0 flex-column flex-sm-row">
                    <div>
                        {selected.length} files selected
                    </div>
                    <Form className='d-flex'>
                        <Control size="sm"
                            placeholder="Search For Files"
                            className="m-1"
                            value={search}
                            onChange={e => setSearch(e.currentTarget.value)}
                        />

                        <Control as="select"
                            size="sm"
                            className="m-1"
                            custom
                            value={filter}
                            onChange={e => setFilter(e.currentTarget.value)}
                        >
                            <option value="">Filter By</option>
                            <option value="images">Images</option>
                            <option value="videos">Videos</option>
                            <option value="pdfs">PDFs</option>
                            {/* <option value="mine">Uploaded By Me</option>
                            <option value="shared">Shared With Me</option> */}
                        </Control>
                    </Form>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        {files
                            .filter(f => selected.indexOf(f.id) !== -1)
                            .map(f => (
                                <span style={itemStyle} className="text-white" key={f.id}>
                                    {f.title}
                                    <a href="#." className="ms-2 text-light" onClick={e => removeFile(e, f.id)}>
                                        <i className="fas fa-times" />
                                    </a>
                                </span>
                            ))}
                    </div>

                    <ListGroup>
                        {displayFiles.map(f => (
                            <ListGroup.Item key={f.id}
                                action
                                className="d-flex align-items-center"
                                onClick={() => handleClick(f.id)}
                                active={selected.indexOf(f.id) !== -1}
                            >
                                <i className={icons[f.file_group] + " fa-3x"} />
                                <div className="ms-3">
                                    <h6 className="mb-0 font-weight-bold">{f.title}</h6>
                                    <div>{f.filesize || f.size} MB</div>
                                    <div className="small text-muted" style={{ lineHeight: 1 }}>
                                        Uploaded on {moment(f.date_added).format("DD MMMM YYYY")}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <span className="d-inline-block small me-auto text-muted">
                        {files.length} files
                    </span>
                    <Button variant="success" className="px-2 px-sm-3 m-1 rounded-pill" disabled={selected.length === 0} onClick={submitFiles}>
                        <i className="fas fa-thumbs-up me-1 me-sm-2" />Add Selected
                    </Button>
                    <Button variant="secondary" className="rounded-pill px-2 px-sm-3 m-1" onClick={() => setShow(false)} >
                        <i className="fas fa-times-circle me-1 me-sm-2"></i>Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}


/**
 *
 * @param {{
 * maxFiles? : number
 * onChange: (files: string[]) => void
 * value: string[]
 * allowUpload: boolean
 * required: false
 * errorText: string
 * placeholder: string
 * fileOptions: {id: string, title: string, category: string, filesize: number}[]
 * }} props
 */
const FileInput = ({ maxFiles = 1, onChange, value = [], allowUpload = true, required = false, errorText, placeholder, fileOptions = [] }) => {

    const values = value.filter(i => i);

    const [files, setFiles] = useState([]);

    const { profile } = useContext(AppUserContext);

    useEffect(() => {

        // const file_types = ['images', 'pdfs', 'sheets', 'docs', 'slides', 'videos', 'others'];

        if (fileOptions.length === 0) {
            getFiles(profile.id)
                .then(({ files }) => setFiles(files))
        }

        setFiles(fileOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileOptions.length])

    /**
     * handle the upload of a file. 
     * set it as selected value or among selected values.
     * @param {{id: string, title: string, description: string}} file 
     */
    const handleFileUpload = file => {
        if (maxFiles === 1) {
            onChange([file.id]);
        } else {
            onChange([...value, file.id]);
        }

        setFiles(f => [...f, file]);
    }

    /**
     * Remove a file from the selected list
     * @param {React.MouseEvent} e 
     * @param {string} fileid 
     */
    const removeFile = (e, fileid) => {
        e.preventDefault();
        onChange(values.filter(i => i !== fileid));
    }

    /**
     * On hit the delete key in the input field, remove item there.
     * @param {React.KeyboardEvent} e 
     */
    const handleKeyUp = e => {

        let isBackspace = false;

        if ('key' in e) {
            isBackspace = (e.key === 'Backspace');
        } else if ('keyCode' in e) {
            isBackspace = (e.keyCode === 8);
        }

        if (!isBackspace) return;

        onChange([]);
    }

    const chosenfiles = files.filter(f => values.indexOf(f.id) !== -1)

    if (maxFiles === 1) {
        return (
            <Group>
                <Form.Control
                    placeholder={placeholder || "Search or upload a file"}
                    value={chosenfiles.length > 0 ? chosenfiles[0].title : ""}
                    onKeyUp={handleKeyUp}
                    onChange={() => null}
                    required={required}
                />

                {(files.length > 1) &&
                    <FileSelector size="sm"
                        variant="link"
                        files={files.filter(i => values.indexOf(i.id) === -1)}
                        maxFiles={1}
                        onChooseFiles={files => onChange(files)}
                    >
                        <i className="fas fa-search text-secondary" />
                    </FileSelector>
                }


                {(allowUpload && values.length === 0) &&

                    <FileUpload size="sm" variant="link" onUpload={file => handleFileUpload(file)}>
                        <i className="fas fa-plus-circle text-secondary" />
                    </FileUpload>
                }

                {required &&
                    <Form.Control.Feedback type="invalid">
                        {errorText || "A file must be selected"}
                    </Form.Control.Feedback>}
            </Group>
        )
    }

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center">
                <div>
                    {values.length > 0 ? chosenfiles.map(f => (
                        <span style={itemStyle} className="text-white" key={f.id}>
                            {f.title}
                            <a href="#." className="ms-2 text-light" onClick={e => removeFile(e, f.id)}>
                                <i className="fas fa-times" />
                            </a>
                        </span>
                    )) : "No files selected"}
                </div>
                <div>

                    {(!maxFiles || (values.length < maxFiles)) &&
                        <FileSelector size="sm"
                            variant="link"
                            files={files.filter(i => values.indexOf(i.id) === -1)}
                            maxFiles={maxFiles ? maxFiles - values.length : false}
                            onChooseFiles={files => onChange([...values, ...files])}
                        >
                            <i className="fas fa-search me-1" />Search
                        </FileSelector>
                    }

                    {((!maxFiles || values.length < maxFiles) && allowUpload) &&
                        <FileUpload size="sm" variant="link" onUpload={file => handleFileUpload(file)}>
                            <i className="fas fa-plus-circle me-1" />Upload
                        </FileUpload>
                    }


                </div>
            </div>

            {required &&
                <div>
                    <Form.Control value={values.join(",")} onChange={() => null} required className="d-none" />
                    <Form.Control.Feedback type="invalid">
                        {errorText || "At least one file must be selected."}
                    </Form.Control.Feedback>
                </div>
            }
        </>
    )
}

export { FileUpload, FileSelector };
export default FileInput;