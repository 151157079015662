
/**
 * generates a random unique ID. 
 * Not totally unique
 */
const generateID = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
}

/**
 * Loads a style sheet with src given 
 * then returns an ID for the stylesheet
 * @param {string} src 
 * @returns id for stylesheet
 */
export const loadStyleSheet = src => {
    const id = generateID(),
        link = document.createElement('link');

    link.id = id;
    link.rel = 'stylesheet';
    link.href = src;

    document.head.appendChild(link);

    return id;
}

/**
 * 
 * @param {string} string 
 */
export const UCWords = string => string.split(' ').map(s => s[0].toUpperCase() + s.slice(1)).join(' ');

/**
 * options for overriding toastr notifications.
 */
export const toastrOptions = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "600",
    "hideDuration": "1000",
    "timeOut": "5500",
    "extendedTimeOut": "2000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}



const getDistanceFromLatLonInKm = (place1, place2) => {
    let lat1 = parseFloat(place1.lat),
        lat2 = parseFloat(place2.lat),
        lon1 = parseFloat(place1.lon),
        lon2 = parseFloat(place2.lon);

    const R = 6371, // Radius of the earth in km
        dLat = deg2rad(lat2 - lat1), // deg2rad below
        dLon = deg2rad(lon2 - lon1),
        a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2),
        c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
}

const deg2rad = deg => deg * (Math.PI / 180);


/**
 * Unloads stylesheet with given ID.
 * @param {string} id 
 */
export const unLoadStyleSheet = id => {
    document.getElementById(id).remove();
}

/**
 * 
 * @param {Object} a 
 * @param {Object} b 
 * @param {string} field 
 * @param {"asc"|"desc"} order 
 */
export const sortFunction = (a, b, field, order = "asc") => {

    if (order === "desc") {
        return (b[field] < a[field] ? -1 : (
            b[field] > a[field] ? 1 : 0
        ))
    }

    return (a[field] < b[field] ? -1 : (
        a[field] > b[field] ? 1 : 0
    ))

}

/**
 * 
 * @param {string} email 
 * @returns 
 */
const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * 
 * @param {number[]} values 
 * @param {boolean} skip0s 
 * @returns {number}
 */
const arrayAverage = (values, skip0s = true) => {
    values = values.filter(v => v !== null);

    if (skip0s) values = values.filter(v => v !== 0);

    return (values.reduce((p, c) => p + c, 0)) / values.length;
}

export { getDistanceFromLatLonInKm, deg2rad, validateEmail, arrayAverage };

