
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
import { ErrorLoading } from '../../components/loading';
import ExpenseForm from './expense-form';

const expense = {
    category: '',
    expense_date: '',
    title: '',
    isItemized: false,
    description: '',
    account_id: '',
    feenote_id: '',
    isBillable: false,
    isTaxInclusive: true,
    amount: '',
    receipt_file_id: '',
    tax_percent: 0,
    payment_method: "",
    items: []
}

const NewExpense = () => {

    const nav_items = [
        { title: 'Expenses', href: '/app/expenses' },
        { title: 'New Expense' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate(`/app/expenses/${details.id}`);
    }

    const { profile } = useContext(AppUserContext);

    if (profile.roles.indexOf('finance') === -1) {
        return <ErrorLoading>You do not have permission to create an expense.</ErrorLoading>;
    }



    return (
        <>
            <Nav items={nav_items} />

            <ExpenseForm
                details={expense}
                action="create"
                onSubmit={handleSubmit}
            />
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/expenses').ExpenseObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/expenses').ExpenseObject>>} props.setDetails 
 */
const EditExpense = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Expenses', href: '/app/expenses' },
        { title: details.reference, href: `/app/expenses/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/expenses/${details.id}`);
    }

    const { profile } = useContext(AppUserContext);

    if (profile.roles.indexOf('finance') === -1) {
        return <ErrorLoading>You do not have permission to update an expense.</ErrorLoading>;
    }


    return (

        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <ExpenseForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { EditExpense };


export default NewExpense;