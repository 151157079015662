import { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Chart, registerables } from 'chart.js';
import moment from "moment";
import { graphColours } from "../../../assets/constants";

Chart.register(...registerables);

/**
 * 
 * @param {Object} props
 * @param {{
 * [client_id: string]: {title: string, titles: {[year_month: string]: {}[]}} props.titles
 * }} 
 * @returns 
 */
const FinanceSummary = ({ feenotes = {}, titles = {} }) => {

    const consts = {
        borderWidth: 1,
        yAxisID: 'yAxis1',
        categoryPercentage: 0.95
    }

    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);

    useEffect(() => {

        let m = parseInt(moment().format("M")) - 12,
            y = parseInt(moment().format("y"));

        if (m < 1) {
            m += 12;
            y -= 1;
        }

        const months = [];
        let mm, yy;

        for (let i = 1; i <= 12; i++) {
            mm = m + i;
            yy = y;

            if (mm > 12) {
                mm -= 12;
                yy += 1;
            }
            months.push({
                key: `${yy}_${mm}`,
                title: moment([y, mm - 1]).format("MMM")
            });
        }


        const keys = Object
            .keys(feenotes)
            .map((k, index) => ({
                label: feenotes[k].title,
                data: months.map(m => {
                    if (m.key in feenotes[k].feenotes) {
                        return feenotes[k].feenotes[m.key].reduce((p, c) => p + c, 0)
                    } else {
                        return 0;
                    }
                }),
                ...graphColours[index],
                ...consts
            }))


        const t_keys = months.map(m => {

            let data = [], client;

            for (let ind in titles) {
                client = titles[ind];
                if (m.key in client.titles) {
                    data.push(client.titles[m.key].length)
                }
            }

            return data.reduce((p, c) => c + p, 0);
        })

        setData(keys.concat({
            label: 'No. of Titles',
            data: t_keys,
            backgroundColor: 'rgba(255, 206, 86, 0)',
            borderColor: 'rgba(0, 123, 255, 1)',
            borderWidth: 1,
            type: 'line',
            yAxisID: 'yAxis2',
            categoryPercentage: 0.95
        }));

        setLabels(months.map(m => m.title))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(feenotes), JSON.stringify(titles)])

    return (
        <Card>
            <Card.Header className="d-flex align-items-center justify-content-between py-3">
                <h4 className='my-0'>Fee Note Sum &amp; Titles Received</h4>
                {/* <span>
                    <span className="font-weight-normal">25</span>
                    <span className="text-success ms-3">
                        <i className="fas fa-arrow-up small" /> 5.00%
                    </span>
                </span> */}
            </Card.Header>
            <Card.Body>
                <div style={{ maxHeight: '600px' }}>
                    <FinanceChart labels={labels} data={data} />
                </div>
            </Card.Body>
        </Card>
    )
}


const FinanceChart = ({ data, labels }) => {

    const canvasElement = useRef();


    useEffect(() => {
        const chart = new Chart(canvasElement.current, {
            type: 'bar',
            data: {
                labels,
                datasets: data
            },
            options: {
                scales: {
                    yAxis1: {
                        stacked: true,
                        title: {
                            display: true,
                            text: "Amount (UGX)"
                        }

                    },
                    yAxis2: {
                        beginAtZero: true,
                        position: "right",
                        title: {
                            display: true,
                            text: "Count of Titles"
                        },
                        grid: {
                            drawOnChartArea: false
                        }
                    },
                    x: {
                        stacked: true
                    }
                }
            }
        });

        return () => chart.destroy();
    }, [data, labels])

    return <canvas ref={canvasElement} width="100%" />
}

export default FinanceSummary;