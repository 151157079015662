import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ListGroup, Accordion, Row, Col } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import { AppUserContext } from "../../App";
import { fileIcons, titleStatusActivity, titleStatuses } from "../../assets/constants";
import Nav from '../../components/breadcrumb';
import cur from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { ConfirmAction, PromptAction } from "../../components/prompt";
import { sortFunction, UCWords } from "../../components/resources";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { advanceTitleStatus, deleteNote, getTitle, reverseTitleStatus, updateTitle } from "../../resources/api/titles";
import { APIURL } from "../../resources/fetch";
import UploadComment from "./details/comments";
import UploadDocument from "./details/upload-document";
import { EditTitle } from "./title-maniplulation";
import ViewTitles from "./details/titles";
import ChooseOthers from "./details/advance";

const { Header, Body, Item } = Accordion;


/**
 * 
 * @param {Object} props
 * @param {import("./view-titles").TitleObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import("./view-titles").TitleObject>>} props.setDetails
 */
const ViewDetails = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Titles', href: "/app/titles" },
        { title: details.title }
    ]

    const { profile } = useContext(AppUserContext);
    const [show, setShow] = useState(false);
    const [action, setAction] = useState("advance");
    const [reason, setReason] = useState();

    const statusIndex = titleStatuses.findIndex(e => e === details.status);

    const batch_titles_selection = useMemo(() =>
        details.batch_titles.filter(t => t.status === details.status),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(details.batch_titles), details.status])

    const onDocUpload = (document) => setDetails(d => ({ ...d, documents: d.documents.concat(document) }))

    const onDeleteNote = id => {

        infoAlert('Deleting note, please hold...');

        deleteNote(id)
            .then(({ message }) => {
                setDetails(d => ({ ...d, notes: d.notes.filter(n => n.id !== id) }));
                successAlert(message);
            })
            .catch(e => errorAlert(e));

    }

    const handleNoteAdd = item => setDetails(d => ({ ...d, notes: d.notes.concat(item) }))

    const handleReverse = () => {
        infoAlert("Please hold...");
        reverseTitleStatus(details.id, titleStatuses[0])
            .then(({ title, message }) => {
                title.activity.sort((a, b) => sortFunction(a, b, 'date_added', 'asc'));
                setDetails(d => ({ ...d, ...title }));
                successAlert(message);
            })
            .catch(e => errorAlert(e));
    }

    const handleReturned = () => {
        setAction("close");
        setShow(true);
    }

    const handleUnresolved = reason => {
        setReason(reason);
        setAction("cancel");
        setShow(true);

    }

    const handleAdvance = () => {

        if ((batch_titles_selection.length === 0) && (statusIndex !== 4)) {
            infoAlert("Advancing title status...");

            return advanceTitleStatus(details.id, { activity_date: "", batch_titles: [] })
                .then(({ title, message }) => {
                    title.activity.sort((a, b) => sortFunction(a, b, 'date_added', 'asc'));
                    setDetails(d => ({ ...d, ...title }));
                    successAlert(message);
                    setShow(false);
                })
                .catch(e => errorAlert(e));

        } else {
            setAction("advance");
            setShow(true);
        }
    }

    const handleInstrumentNumber = instrument_no => {

        infoAlert("Uploading instrument no ...");

        updateTitle({ instrument_no }, details.id)
            .then(({ title, message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, instrument_no: title.instrument_no }))

            })
            .catch(errorAlert);
    }



    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title}</Title>
                <ActionBar>

                    {(statusIndex === 6) &&
                        <ButtonToolbar>
                            <ConfirmAction
                                as={Btn}
                                title="Mark as returned"
                                confirmationText="Are you sure you want to mark this title as Returned? The return letter must have been uploaded before hand."
                                handleAction={handleReturned}
                            >
                                <i className="fas fa-check" />
                            </ConfirmAction>
                            <PromptAction
                                as={Btn}
                                title="Mark as unresolved"
                                promptMessage="Input the reason as to why the title is unresolved."
                                onAccept={handleUnresolved}

                            >
                                <i className="fas fa-question-circle" />
                            </PromptAction>
                        </ButtonToolbar>}

                    {((statusIndex >= 6) && !details.instrument_no) &&
                        <ButtonToolbar>
                            <PromptAction
                                as={Btn}
                                title="Upload Instrument Number"
                                promptMessage="Input the instrument number."
                                onAccept={handleInstrumentNumber}

                            >
                                <i className="fas fa-hashtag" />
                            </PromptAction>
                        </ButtonToolbar>}

                    {(([0, 3, 4, 5].indexOf(statusIndex)) !== -1) &&
                        <ButtonToolbar>
                            <ConfirmAction
                                as={Btn}
                                title="Advance to next stage"
                                confirmationText={`Are you sure you want to advance this title to ${UCWords(titleStatuses[statusIndex + 1].replace(/-/g, " "))}?`}
                                cancelBtnText="No"
                                handleAction={handleAdvance}
                            >
                                <i className="far fa-arrow-alt-circle-right" />
                            </ConfirmAction>
                        </ButtonToolbar>}

                    {(details.status === titleStatuses[1]) &&
                        <ButtonToolbar>
                            <ConfirmAction
                                as={Btn}
                                title="Reverse to previous stage"
                                confirmationText={`Are you sure you want to reverse this title to Received?`}
                                cancelBtnText="No"
                                handleAction={handleReverse}
                            >
                                <i className="far fa-arrow-alt-circle-left" />
                            </ConfirmAction>
                        </ButtonToolbar>}
                    <ButtonToolbar>
                        {(details.status === titleStatuses[0]) &&
                            <Btn href={`/app/titles/${details.id}/edit`} title="Edit Details">
                                <i className="fas fa-pencil-alt" />
                            </Btn>}

                        <Btn href="/app/titles/new" title="New Title">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader >

            <div className="max-1000">

                <Accordion defaultActiveKey="0" flush>
                    <Item eventKey="0">
                        <Header>
                            <h4 className="my-1">Details</h4>
                        </Header>
                        <Body>
                            <Row className="g-0">
                                <Col sm={6}>
                                    <dl>
                                        <dt>Account</dt>
                                        <dd>
                                            <Link to={`/app/accounts/${details.account_id}`}>
                                                {details.account.title}
                                            </Link>
                                        </dd>
                                        <dt>Batch Ref</dt>
                                        <dd>
                                            {details.batch_ref || "n/a"}
                                            {!!details.batch_ref && <Link className="small ms-2" to={`/app/titles/new?account=${details.account_id}&batch_ref=${details.batch_ref}`}>Add title in same batch</Link>}

                                        </dd>
                                        <dt>Instruction Date</dt>
                                        <dd>{moment(details.instruction_date).format("dddd DD MMMM YYYY")}</dd>
                                        <dt>Customer</dt>
                                        <dd>{details.customer}</dd>
                                        <dt>Proprietor</dt>
                                        <dd>{details.registered_proprietor || <span className="text-muted">n/a</span>}</dd>
                                        <dt>County</dt>
                                        <dd>{details.county}</dd>
                                        <dt>Land Office</dt>
                                        <dd>{details.land_office}</dd>
                                    </dl>
                                </Col>
                                <Col sm={6}>
                                    <dl>
                                        <dt>Status</dt>
                                        <dd>{UCWords(details.status.replace(/-/g, " "))}</dd>
                                        <dt>Loan amount</dt>
                                        <dd>{cur(details.loan_amount, 0).format()}</dd>
                                        <dt>Fee Note</dt>
                                        <dd>
                                            {!details.feenote_id ?
                                                <span className="text-muted">n/a</span> :
                                                <>
                                                    Status: {UCWords(details.feenote.status.replace(/-/g, " "))}
                                                    {(profile.roles.indexOf("finance") === -1) ?
                                                        <a href={`${APIURL}/files/feenote/${details.feenote.id}`} target="_blank" rel="noreferrer" className="ms-2 ms-md-3 small">
                                                            <i className="fas fa-link me-1" />{details.feenote.reference}
                                                        </a>
                                                        :
                                                        <Link className="ms-2 ms-md-3 small" to={`/app/fee-notes/${details.feenote.id}`}>
                                                            <i className="fas fa-link me-1" />{details.feenote.reference}
                                                        </Link>
                                                    }

                                                </>
                                            }

                                        </dd>
                                        {!!details.dispatch_date &&
                                            <>
                                                <dt>Dispatch Date</dt>
                                                <dd>{moment(details.dispatch_date).format('dddd DD MMMM YYYY')}</dd>
                                            </>}
                                        {!!details.return_date &&
                                            <>
                                                <dt>Return Date</dt>
                                                <dd>{moment(details.return_date).format('dddd DD MMMM YYYY')}</dd>
                                            </>}
                                        {!!details.instrument_no &&
                                            <>
                                                <dt>Instrument No</dt>
                                                <dd>{details.instrument_no}</dd>
                                            </>}
                                        <dt>Turn Around</dt>
                                        <dd>
                                            {(["returned", "unresolved"].indexOf(details.status) !== -1) ?
                                                `${details.turnaround_time} days` :
                                                <span className="text-muted">not returned yet</span>
                                            }

                                        </dd>
                                        <dt>Added</dt>
                                        <dd>{moment(details.date_added).format("dddd DD MMMM YYYY, HH:mm")}</dd>
                                    </dl>
                                </Col>
                            </Row>

                        </Body>
                    </Item>

                    <Item eventKey="1">
                        <Header>
                            <h4 className="my-1">Documents</h4>
                        </Header>
                        <Body>

                            {(details.documents.length === 0) &&
                                <>
                                    <p className="lead">
                                        No documents have been uploaded for this title.
                                    </p>
                                </>
                            }
                            <ListGroup variant="flush">
                                {details.documents.map(d => (
                                    <ListGroup.Item key={d.id} className="d-flex align-items-center">

                                        <i className={`${fileIcons[d.file_group]} fa-4x me-3 me-sm-4`} />
                                        <div>
                                            <h6 className="mb-1">{d.file_title}</h6>
                                            <p className="mb-0 text-muted">
                                                {UCWords(d.category.replace(/-/g, " "))}
                                            </p>
                                            <p className="mb-1 text-muted">
                                                {d.display_name} | {moment(d.date_added).format("DD MMM YYYY, HH:mm")}
                                            </p>
                                            <div className="hstack gap-3 small">
                                                <a href={`${APIURL}/files/${d.file_slug}/view`} target={`_blank`}>
                                                    <i className="fas fa-eye me-1" />View
                                                </a>
                                                <a href={`${APIURL}/files/${d.file_id}/download`} target={`_blank`}>
                                                    <i className="fas fa-download me-1" />Download
                                                </a>
                                            </div>
                                        </div>

                                    </ListGroup.Item>
                                ))}
                            </ListGroup>

                            <div className="my-2">
                                <UploadDocument
                                    onUpload={onDocUpload}
                                    title_id={details.id}
                                />
                            </div>

                        </Body>
                    </Item>

                    <Item eventKey="2">
                        <Header>
                            <h4 className="my-1">Activity</h4>
                        </Header>
                        <Body>

                            {(details.activity.length === 0) &&
                                <p className="lead">
                                    No activity has been registered for this title.
                                </p>}

                            <ListGroup variant="flush" style={{ maxWidth: "576px" }}>
                                {details.activity.map(a => (
                                    <ListGroup.Item key={a.id}>
                                        <h6 className="text-primary mb-1">
                                            {/* <i className="far fa-handshake fa-fw me-2" /> */}
                                            {/* {UCWords(a.status.replace(/-/g, " "))} */}
                                            {titleStatusActivity[a.status]}
                                        </h6>
                                        <small className="fw-bold text-muted">{moment(a.date_added).format("DD MMMM YYYY, HH:mm")}</small> by
                                        <span className="mb-1 ms-1">
                                            {a.display_name}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>

                            {/* <ViewActivity /> */}
                        </Body>
                    </Item>

                    <Item eventKey="3">
                        <Header>
                            <h4 className="my-1">Comments</h4>
                        </Header>
                        <Body>
                            <Notes
                                titleid={details.id}
                                notes={details.notes || []}
                                addNote={handleNoteAdd}
                                deleteNote={onDeleteNote}
                            />
                        </Body>
                    </Item>

                    {(details.batch_titles.length > 0) &&
                        <Item eventKey="4">
                            <Header>
                                <h4 className="my-1">Batch Titles</h4>
                            </Header>
                            <Body>
                                <ViewTitles titles={details.batch_titles} />
                            </Body>
                        </Item>
                    }
                </Accordion>
            </div>

            <ChooseOthers
                titles={batch_titles_selection}
                toShow={{ show, setShow }}
                status={details.status}
                setTitleDetails={setDetails}
                title_id={details.id}
                reason={reason}
                action={action}
            />

        </>
    )

}


/**
 * @param {Object} props
 * @param {string} props.titleid 
 * @param {import("../../resources/api/events").NoteObject[]} props.notes 
 * @param {(details: import("../../resources/api/events").NoteObject) => void} props.addNote 
 * @param {(id: string) => void} props.deleteNote 
 * @returns 
 */
const Notes = ({ titleid, notes, addNote, deleteNote }) => {

    const { profile } = useContext(AppUserContext);

    const handleDelete = (e, id) => {
        e.preventDefault();
        infoAlert("Just a minute...");
        deleteNote(id);
    }

    if (notes.length === 0) {
        return (
            <>
                <p className="lead">
                    No comments have been added to this title.
                </p>
                <div className="my-2">
                    <UploadComment titleid={titleid} onCreate={addNote} />
                </div>
            </>
        )
    }

    return (
        <>

            <ListGroup variant="flush">

                {notes.map(n => (
                    <ListGroup.Item className="px-1" key={n.id}>
                        <div className="d-flex justify-content-between align-items-center">
                            <small className="text-muted text-uppercase">
                                {n.display_name}, {moment(n.date_added).format('DD MMMM YYYY, HH:mm')}
                            </small>
                            {(profile.id === n.added_by) &&
                                <a href="#." className="text-danger small" onClick={e => handleDelete(e, n.id)}>
                                    <i className="fas fa-times me-1" />Delete
                                </a>}

                        </div>

                        <p className="mb-1" dangerouslySetInnerHTML={{ __html: n.content.replace(/\n/g, "<br />") }} />
                    </ListGroup.Item>
                ))}
            </ListGroup>

            <div className="my-2">
                <UploadComment titleid={titleid} onCreate={addNote} />
            </div>
        </>
    )
}



const TitleDetails = () => {

    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const { titleid } = useParams();

    useEffect(() => {

        setLoaded(false);
        setError(null);

        getTitle(titleid, ['account', 'feenote', 'documents', 'notes', 'activity', 'batch_titles'])
            .then(({ title }) => {
                title.documents.sort((a, b) => sortFunction(a, b, "date_added", "asc"));
                title.notes.sort((a, b) => sortFunction(a, b, "date_added", "asc"));
                title.activity.sort((a, b) => sortFunction(a, b, "date_added", "asc"));
                setDetails(title)
            })
            .catch(err => setError(err))
            .finally(() => setLoaded(true))



    }, [titleid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading title details...</PageLoading>
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/edit" element={<EditTitle details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default TitleDetails;